import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { NotificationMessageType, NotificationState } from './Types'

const initialState: NotificationState = {
    message: null,
    variant: 'primary',
    show: false,
}

export const shareMenuSlice = createSlice({
    name: 'Notification',
    initialState,
    reducers: {
        setMessage(state, action: PayloadAction<{ message: string | null, variant?: NotificationMessageType }>) {
            state.message = action.payload.message
            state.variant = action.payload.variant ?? 'primary'
            state.show = true
        },
        hideMessage(state) {
            state.show = false
        },
    },
})

export const {
    setMessage,
    hideMessage,
} = shareMenuSlice.actions
export default shareMenuSlice.reducer