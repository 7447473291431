import React from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

export type FormInput = {
    terms: string
    isActive: boolean
}

interface IMerchantTermsEditFormProps {
    handleSubmit(data: FormInput): void
    terms: string
    isActive: boolean
    disabled: boolean
}

const MerchantTermsEditForm: React.FC<IMerchantTermsEditFormProps> = (props: IMerchantTermsEditFormProps) => {

    const initState: FormInput = {
        terms: props.terms,
        isActive: props.isActive,
    }

    const [initialValues] = React.useState(initState)

    const onSubmit = (values: FormInput) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        // reValidateMode: "onChange",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="Terms">
                <Form.Label>Terms</Form.Label>
                <Form.Control
                    type="text"
                    as="textarea"
                    rows={10}
                    placeholder="Terms"
                    {...register("terms")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="IsActive">
                <Form.Check
                    type="checkbox"
                    label="Active"
                    {...register("isActive")}
                />
            </Form.Group>

            <Button
                style={{ marginTop: 10 }}
                type='submit'
                variant="primary"
                disabled={!isValid || props.disabled}
            >Save</Button>
        </Form>

    )
}

export default MerchantTermsEditForm