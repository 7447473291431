import { Col, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { useGetStatsAccountingQuery } from "../../Store/Stats/Stats.service";
import { NumericFormat } from "react-number-format";
import { useListMerchantsCompleteQuery } from "../../Store/Merchants/Merchants.service";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/Store";
import { Link } from "react-router-dom";
import { StatsAccountingStatus, anoStatuses } from "../../Store/Stats/Types";
import { useEffect, useState } from "react";

function StatsAccountingComponent() {

    const statsQuery = useGetStatsAccountingQuery()

    const [total, setTotal] = useState(0)

    useEffect(() => {
        if (statsQuery.data) {
            const total = statsQuery.data.COMPLETED.totalIncome +
                statsQuery.data.PENDING.totalIncome +
                statsQuery.data.REJECTED.totalIncome
            setTotal(total)
        }
    }, [statsQuery.data])

    return (
        <Row>
            {statsQuery.isLoading ? <Col>Loading...</Col> : null}
            {statsQuery.data ? <Col>
                <Tab.Container id="left-tabs-example" defaultActiveKey={anoStatuses[0]}>
                    <Nav variant="pills" className="flex-row mb-3">
                        {anoStatuses.map(status => <Nav.Item key={status}>
                            <Nav.Link eventKey={status} className="text-capitalize">{status.toLowerCase()}</Nav.Link>
                        </Nav.Item>)}
                    </Nav>
                    <Tab.Content>
                        {anoStatuses.map(status => <Tab.Pane eventKey={status} key={status}>
                            <StatsAccountingStatusComponent
                                stat={statsQuery.data ? statsQuery.data[status] : undefined}
                                isLoading={statsQuery.isLoading}
                                status={status.toLowerCase()}
                                total={total}
                            />
                        </Tab.Pane>)}
                    </Tab.Content>
                </Tab.Container>
            </Col> : null}
        </Row>
    );
}

function StatsAccountingStatusComponent(props: {
    stat?: StatsAccountingStatus
    isLoading: boolean
    status: string
    total: number
}) {

    useListMerchantsCompleteQuery()
    const merchantKeys = useSelector((state: RootState) => state.merchants.merchantKeys)

    const percent = props.stat && props.total > 0 ? (props.stat.totalIncome * 100) / props.total : 0

    return (
        <>
            <Row className="mb-3">
                <Col className="h3">
                    <span className="text-capitalize">{props.status}</span>: <NumericFormat
                        value={props.stat?.totalIncome}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                        decimalScale={2}
                    />/<NumericFormat
                        value={props.total}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                        decimalScale={2}
                    /> (<NumericFormat
                        value={percent}
                        displayType="text"
                        thousandSeparator={true}
                        suffix="%"
                        decimalScale={2}
                    />)
                </Col>
            </Row>
            <Row>
                {props.isLoading ? <Col>Loading...</Col> : null}
                {!props.isLoading ? <Col>
                    <Tabs
                        defaultActiveKey="network"
                        id="uncontrolled-tab"
                        className="mb-3"
                    >
                        <Tab eventKey="network" title="Network">
                            {props.stat?.incomeByNetwork?.map(rec => <Row key={rec.networkId}>
                                <Col xs={12} md={6}>{rec.networkId}</Col>
                                <Col xs={12} md={6} className="text-end">
                                    <NumericFormat
                                        value={rec.commission}
                                        displayType="text"
                                        thousandSeparator={true}
                                        prefix="$"
                                        decimalScale={2}
                                    />
                                </Col>
                                <Col xs={12}><hr /></Col>
                            </Row>)}
                        </Tab>
                        <Tab eventKey="merchant" title="Merchant">
                            {props.stat?.incomeByMerchant?.map(rec => <Row key={rec.merchantId}>
                                <Col xs={12} md={6}>
                                    {merchantKeys && merchantKeys[rec.merchantId] ? <><Link to={'/merchants/' + rec.merchantId}>{merchantKeys[rec.merchantId].name}</Link> | {merchantKeys[rec.merchantId].network}</> : null}
                                </Col>
                                <Col xs={12} md={6} className="text-end">
                                    <NumericFormat
                                        value={rec.commission}
                                        displayType="text"
                                        thousandSeparator={true}
                                        prefix="$"
                                        decimalScale={2}
                                    />
                                </Col>
                                <Col xs={12}><hr /></Col>
                            </Row>)}
                        </Tab>
                    </Tabs>
                </Col> : null}
            </Row>
        </>
    )
}

export default StatsAccountingComponent;
