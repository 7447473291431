import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Store/Store"
import { loadLocalStorageDataAsync } from "../../Store/LocalStorage/LocalStorage.slice"
import { useAuthTokenQuery } from "../../Store/Authorization/Authorization.service"
import { skipToken } from "@reduxjs/toolkit/query"
import { AuthScreens } from "../../Store/Authorization/Types"
import { setScreen } from "../../Store/Authorization/Authorization.slice"
import SignIn from "../SignIn"
import Splash from "../Splash"

function Authorization(props: { children?: any }) {

    const dispatch = useDispatch<AppDispatch>()
    const [isLoading, setIsLoading] = useState(false)
    const isLoadingLocalStorageData = useSelector((state: RootState) => state.localStorage.isLoadingLocalStorageData)
    const token = useSelector((state: RootState) => state.localStorage.token)
    const authScreen = useSelector((state: RootState) => state.authorization.screen)
    const isLoggedIn = useSelector((state: RootState) => state.authorization.isLoggedIn)

    const authTokenArg = isLoadingLocalStorageData || !token ? skipToken : undefined
    const authTokenResult = useAuthTokenQuery(authTokenArg)

    useEffect(() => {
        dispatch(loadLocalStorageDataAsync())
    }, [dispatch])

    useEffect(() => {
        setIsLoading(authTokenResult.isFetching ||
            isLoadingLocalStorageData)
    }, [dispatch, authTokenResult, isLoadingLocalStorageData])

    useEffect(() => {
        if (!isLoadingLocalStorageData && !token) {
            dispatch(setScreen(AuthScreens.SIGN_IN))
        }
    }, [dispatch, isLoadingLocalStorageData, token])

    let screen = <Splash />

    if (!isLoading) {
        switch (authScreen) {
            case AuthScreens.SIGN_IN:
                screen = <SignIn />
                break
        }
    }

    const showChildren = isLoggedIn

    return (
        <>
            {showChildren ? props.children : screen}
        </>
    )
}

export default Authorization
