import { AuthEsavvyBody, AuthResponse } from "@esavvynpm/types"
import { baseSplitApi } from "../../Services/BaseApi"
import { API_URLS } from "../../Services/api-urls"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        authToken: build.query<AuthResponse, void>({
            query: () => API_URLS.adminAuthToken,
        }),
        signInWithEsavvy: build.mutation<AuthResponse, AuthEsavvyBody>({
            query: (credentials) => ({
                url: API_URLS.adminAuthEsavvy,
                method: 'POST',
                body: credentials,
            }),
        }),
    }),
    overrideExisting: false,
})

export const {
    useAuthTokenQuery,
    useSignInWithEsavvyMutation,
} = api