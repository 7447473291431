import { IMerchantScrap } from "@esavvynpm/types"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

export type FormInput = {
    imageUrl: string
    title: string
    text: string
    url: string
    price: string
    previousPrice: string
    isDisabled: boolean
}

interface IMerchantScrapItemEditFormProps {
    handleSubmit(data: FormInput): void
    merchantScrap: IMerchantScrap
    disabled: boolean
}

const MerchantScrapItemEditForm: React.FC<IMerchantScrapItemEditFormProps> = (props: IMerchantScrapItemEditFormProps) => {

    const initState: FormInput = {
        imageUrl: props.merchantScrap.imageUrl,
        title: props.merchantScrap.title,
        text: props.merchantScrap.text ?? '',
        url: props.merchantScrap.url,
        price: props.merchantScrap.price ?? '',
        previousPrice: props.merchantScrap.previousPrice ?? '',
        isDisabled: !!props.merchantScrap.isDisabled,
    }

    const [initialValues] = React.useState(initState)

    const onSubmit = (values: FormInput) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        // reValidateMode: "onChange",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="ImageUrl">
                <Form.Label>Image Url</Form.Label>
                <Form.Control
                    type="url"
                    {...register("imageUrl", { required: "Image URL is required" })}
                />
                {errors.imageUrl && (
                    <Form.Text className="text-danger">
                        {errors.imageUrl.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="IsDisabled">
                <Form.Check
                    type="checkbox"
                    label="Disabled"
                    {...register("isDisabled")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    {...register("title", { required: "Title is required" })}
                />
                {errors.title && (
                    <Form.Text className="text-danger">
                        {errors.title.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Text">
                <Form.Label>Text</Form.Label>
                <Form.Control
                    type="text"
                    {...register("text")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="URL">
                <Form.Label>URL</Form.Label>
                <Form.Control
                    type="url"
                    {...register("url", { required: "URL is required" })}
                />
                {errors.url && (
                    <Form.Text className="text-danger">
                        {errors.url.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Price">
                <Form.Label>Discount Price</Form.Label>
                <Form.Control
                    type="text"
                    {...register("price")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="PreviousPrice">
                <Form.Label>Previous Price</Form.Label>
                <Form.Control
                    type="text"
                    {...register("previousPrice")}
                />
            </Form.Group>

            <Button
                type='submit'
                variant="primary"
                disabled={!isValid || props.disabled}
            >Save</Button>
        </Form>

    )
}

export default MerchantScrapItemEditForm