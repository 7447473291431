import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AuthScreens, AuthorizationContainerState } from './Types'
import LocalStorageService, { LocalStorageKeys } from '../../Services/LocalStorage'
import { api } from './Authorization.service'
import { AuthResponse } from '@esavvynpm/types'
import { clearToken } from '../LocalStorage/LocalStorage.slice'

const initialState: AuthorizationContainerState = {
    isInit: false,
    isLoggedIn: false,
    screen: AuthScreens.SPLASH,
    isAuthenticatingToken: false,
    user: null,
    authenticationError: null,
    isAuthenticating: false,
    isAuthenticationError: false,
    isLoggingOut: false,
    logoutError: null,
}

export const authorizationSlice = createSlice({
    name: 'Authorization',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<AuthResponse>) {
            state.user = action.payload
            state.isLoggedIn = true
        },
        setInit(state) {
            state.isInit = true
        },
        setScreen(state, action: PayloadAction<AuthScreens>) {
            state.screen = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(logoutAsync.fulfilled, (state) => {
                state.user = null
                state.isLoggedIn = false
                state.screen = AuthScreens.SIGN_IN
            })
            .addMatcher(
                api.endpoints.authToken.matchPending,
                (state) => {
                    state.isLoggedIn = true
                    state.user = null
                    state.screen = AuthScreens.SPLASH
                }
            )
            .addMatcher(
                api.endpoints.authToken.matchFulfilled,
                (state, { payload }) => {
                    state.isLoggedIn = true
                    state.screen = AuthScreens.SPLASH
                    state.user = payload
                }
            )
            .addMatcher(
                api.endpoints.authToken.matchRejected,
                (state) => {
                    state.isLoggedIn = true
                    state.user = null
                    state.screen = AuthScreens.SIGN_IN
                }
            )

    }
})

export const logoutAsync = createAsyncThunk(
    'authentication/logout',
    async (args, { dispatch }) => {
        await LocalStorageService.removeItem(LocalStorageKeys.AUTH_TOKEN)
        await LocalStorageService.removeItem(LocalStorageKeys.USER_ID)

        dispatch(clearToken())
        dispatch(api.util.resetApiState())
    }
)

export const {
    setUser,
    setInit,
    setScreen,
} = authorizationSlice.actions
export default authorizationSlice.reducer