import { Provider } from 'react-redux'
import { store } from './Store/Store'
import Authorization from './Components/Authorization'
import Navigation from './Components/Navigation'

function App() {
    return (
        <Provider store={store}>
            <Authorization>
                <Navigation />
            </Authorization>
        </Provider>
    )
}

export default App
