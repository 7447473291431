import { Badge, Breadcrumb, Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetAnoQuery, useUpdateAnoMutation } from "../../Store/Income/Income.service";
import { skipToken } from "@reduxjs/toolkit/query";
import { DateService } from "../../Services/date";
import { useListMerchantsCompleteQuery } from "../../Store/Merchants/Merchants.service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/Store";
import { NumericFormat } from "react-number-format";
import AnoEditForm, { FormInput } from "./ano-edit-form";
import { setMessage } from "../../Store/Notification/Notification.slice";
import AffiliateNetworkOrderInvoice from "./ano-invoices";

function AffiliateNetworkOrder() {

    const dispatch = useDispatch<AppDispatch>()
    const params = useParams()
    const navigate = useNavigate()

    const args = params.anoId ? params.anoId : skipToken
    const anoQuery = useGetAnoQuery(args)

    const anoId = anoQuery.data?._id

    const merchantsQuery = useListMerchantsCompleteQuery()
    const merchantKeys = useSelector((state: RootState) => state.merchants.merchantKeys)

    const isLoading = anoQuery.isLoading || merchantsQuery.isLoading

    const merchantName = merchantKeys && anoQuery.data?.merchantId ? merchantKeys[anoQuery.data.merchantId].name : null

    const statusBadgeText = anoQuery.data?.status === "PENDING" ? 'dark' : undefined
    const statusBadgeBg = anoQuery.data?.status === "COMPLETED" ? 'success' : anoQuery.data?.status === "PENDING" ? 'warning' : 'danger'

    const [updateAno] = useUpdateAnoMutation()

    const handleSubmit = async (body: FormInput) => {
        if (!anoId) {
            return
        }
        try {
            await updateAno({
                anoId,
                body,
            }).unwrap()
            dispatch(setMessage({
                message: 'ANO updated successfully',
                variant: 'success',
            }))
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return (
        <Container fluid>
            {isLoading ? <>
                <Row>
                    <Col xs={12}>Loading...</Col>
                </Row>
            </> : <Tab.Container id="left-tabs-example" defaultActiveKey="view">
                <Row className="mb-3">
                    <Col xs={12}>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate('/')}>Dash</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate('/income')}>Income</Breadcrumb.Item>
                            <Breadcrumb.Item active>{anoQuery.data?._id}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12}>
                        <Nav variant="pills" className="flex-row">
                            <Nav.Item>
                                <Nav.Link eventKey="view">View</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="edit">Edit</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="view">
                                <Row className="mb-3">
                                    <Col className="mb-3" xs={12} md={6}>
                                        <Card>
                                            <Card.Header>Income transaction from {merchantName}</Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={12} md={6}>ID</Col>
                                                    <Col xs={12} md={6} className="text-end">{anoQuery.data?._id}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>AURL</Col>
                                                    <Col xs={12} md={6} className="text-end">{anoQuery.data?.aurlId}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Created</Col>
                                                    <Col xs={12} md={6} className="text-end">{DateService.formatDate(anoQuery.data?.createdAt, 'short')}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Cashback</Col>
                                                    <Col xs={12} md={6} className="text-end">{anoQuery.data?.isCashback ? 'Yes' : 'No'}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Status</Col>
                                                    <Col xs={12} md={6} className="text-end">
                                                        <Badge text={statusBadgeText} bg={statusBadgeBg}>{anoQuery.data?.status}</Badge>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}><hr /></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Network</Col>
                                                    <Col xs={12} md={6} className="text-end">{anoQuery.data?.networkId}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Merchant</Col>
                                                    <Col xs={12} md={6} className="text-end"><Link to={'/merchants/' + anoQuery.data?.merchantId}>{merchantName}</Link></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>User</Col>
                                                    <Col xs={12} md={6} className="text-end"><Link to={'/users/' + anoQuery.data?.userId}>{anoQuery.data?.userId}</Link></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}><hr /></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Affiliate Order ID</Col>
                                                    <Col xs={12} md={6} className="text-end">{anoQuery.data?.orderId}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Invoice</Col>
                                                    <Col xs={12} md={6} className="text-end"><Link to={'/invoices/' + anoQuery.data?.affiliateInvoiceId}>{anoQuery.data?.affiliateInvoiceId}</Link></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}><hr /></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Commission</Col>
                                                    <Col xs={12} md={6} className="text-end">
                                                        <NumericFormat
                                                            value={anoQuery.data?.commission}
                                                            displayType="text"
                                                            thousandSeparator={true}
                                                            prefix="$"
                                                            decimalScale={2}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Original Commission</Col>
                                                    <Col xs={12} md={6} className="text-end">
                                                        <NumericFormat
                                                            value={anoQuery.data?.originalCommission}
                                                            displayType="text"
                                                            thousandSeparator={true}
                                                            prefix={anoQuery.data?.currency}
                                                            decimalScale={2}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Currency Rate</Col>
                                                    <Col xs={12} md={6} className="text-end">{anoQuery.data?.currencyRate}</Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Card>
                                            <Card.Header>Original Record</Card.Header>
                                            <Card.Body>
                                                <div><pre>{JSON.stringify(anoQuery.data?.originalRecord, null, 4)}</pre></div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                {anoQuery.data?.orderId ? <AffiliateNetworkOrderInvoice
                                    orderId={anoQuery.data.orderId}
                                /> : null}
                            </Tab.Pane>
                            <Tab.Pane eventKey="edit">
                                {anoQuery.data ? <AnoEditForm
                                    disabled={anoQuery.isLoading}
                                    ano={anoQuery.data}
                                    handleSubmit={handleSubmit}
                                /> : null}
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>}
        </Container>
    );
}

export default AffiliateNetworkOrder;
