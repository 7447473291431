import { IInfluencerProfile } from "@esavvynpm/types"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

export type FormInput = {
    instantMessaging: boolean
    emailMarketing: boolean
    socialNetworks: boolean
    website: string
    other: string
}

interface IUserInfluencerEditFormProps {
    handleSubmit(data: FormInput): void
    influencerProfile?: IInfluencerProfile
    disabled: boolean
}

const UserInfluencerEditForm: React.FC<IUserInfluencerEditFormProps> = (props: IUserInfluencerEditFormProps) => {

    const initState: FormInput = {
        instantMessaging: !!props.influencerProfile?.instantMessaging,
        emailMarketing: !!props.influencerProfile?.emailMarketing,
        socialNetworks: !!props.influencerProfile?.socialNetworks,
        website: props.influencerProfile?.website ?? '',
        other: props.influencerProfile?.other ?? '',
    }

    const [initialValues] = React.useState(initState)

    const onSubmit = (values: FormInput) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        // reValidateMode: "onChange",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="InstantMessaging">
                <Form.Check
                    type="checkbox"
                    label="Instant Messaging"
                    {...register("instantMessaging")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="EmailMarketing">
                <Form.Check
                    type="checkbox"
                    label="Email Marketing"
                    {...register("emailMarketing")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="SocialNetworks">
                <Form.Check
                    type="checkbox"
                    label="Social Networks"
                    {...register("socialNetworks")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Website">
                <Form.Label>Website</Form.Label>
                <Form.Control
                    type="url"
                    placeholder="https://www.example.com/"
                    {...register("website")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Other">
                <Form.Label>Other</Form.Label>
                <Form.Control
                    type="url"
                    {...register("other")}
                />
            </Form.Group>

            <Button
                type='submit'
                variant="primary"
                disabled={!isValid || props.disabled}
            >Save</Button>
        </Form>

    )
}

export default UserInfluencerEditForm