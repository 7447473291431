import { IUser } from "@esavvynpm/types"
import moment from "moment"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

export type FormInput = {
    firstName: string
    lastName: string
    phone: string
    active: boolean
    inviterUid: string
    isRecommender: boolean
    isInfluencer: boolean
    paypalAccountId: string
    isActivated: boolean
    countryCode: string
    minRedeem: number
    dob: string
}

interface IUserEditFormProps {
    handleSubmit(data: FormInput): void
    user: IUser
    disabled: boolean
}

const UserEditForm: React.FC<IUserEditFormProps> = (props: IUserEditFormProps) => {

    const initState: FormInput = {
        firstName: props.user.firstName ?? '',
        lastName: props.user.lastName ?? '',
        phone: props.user.phone ?? '',
        active: true,
        inviterUid: props.user.inviterUid ?? '',
        isRecommender: true,
        isInfluencer: true,
        paypalAccountId: props.user.paypalAccountId ?? '',
        isActivated: true,
        countryCode: props.user.countryCode ?? '',
        minRedeem: props.user.minRedeem ?? 25,
        dob: props.user.dob ?? moment().subtract(18, 'years').format('YYYY-MM-DD')
    }

    const [initialValues] = React.useState(initState)

    const onSubmit = (values: FormInput) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        // reValidateMode: "onChange",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="FirstName">
                <Form.Label>Given Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Given name"
                    {...register("firstName", { required: "Given name is required" })}
                />
                {errors.firstName && (
                    <Form.Text className="text-danger">
                        {errors.firstName.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="LastName">
                <Form.Label>Surname</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Surname"
                    {...register("lastName", { required: "Surname is required" })}
                />
                {errors.lastName && (
                    <Form.Text className="text-danger">
                        {errors.lastName.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Phone"
                    {...register("phone")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="DOB">
                <Form.Label>DOB</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="DOB"
                    {...register("dob")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="PayPalAccountId">
                <Form.Label>PayPalAccountId</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="PayPalAccountId"
                    {...register("paypalAccountId")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="MinRedeem">
                <Form.Label>Minimal Redeem</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Minimal Redeem"
                    {...register("minRedeem")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="InviterUid">
                <Form.Label>Inviter UserID</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Inviter user ID"
                    {...register("inviterUid")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CountryCode">
                <Form.Label>Country</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Country code"
                    {...register("countryCode")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Active">
                <Form.Check
                    type="checkbox"
                    label="Active"
                    {...register("active")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="IsActivated">
                <Form.Check
                    type="checkbox"
                    label="Account activated"
                    {...register("isActivated")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="IsRecommender">
                <Form.Check
                    type="checkbox"
                    label="Recommender"
                    {...register("isRecommender")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="IsInfluencer">
                <Form.Check
                    type="checkbox"
                    label="Influencer"
                    {...register("isInfluencer")}
                />
            </Form.Group>

            <Button
                type='submit'
                variant="primary"
                disabled={!isValid || props.disabled}
            >Save</Button>
        </Form>

    )
}

export default UserEditForm