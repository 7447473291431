import { IAffiliateNetworkOrder, IInvoice, IRewardGeneratingEvent, PaginationResult } from "@esavvynpm/types"
import { baseSplitApi } from "../../Services/BaseApi"
import { API_URLS } from "../../Services/api-urls"
import { PaginatedQueryPayload } from "../Types"
import { AnoUpdatePayload } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listAnos: build.query<PaginationResult<IAffiliateNetworkOrder>, PaginatedQueryPayload>({
            query: ({ params }) => {
                let queryParams = `?page=${params.page}`;
                if (params.searchQuery) {
                    queryParams += `&q=${params.searchQuery}`;
                }
                if (params.field) {
                    queryParams += `&field=${params.field}`;
                }
                if (params.sort) {
                    queryParams += `&sort=${params.sort}`;
                }
                if (params.customFilters) {
                    queryParams += `&filters=${btoa(JSON.stringify(params.customFilters))}`
                }
                return API_URLS.anos + queryParams
            },
            providesTags: ["ListAnos"]
        }),
        updateAno: build.mutation<void, { anoId: string, body: AnoUpdatePayload }>({
            query: ({ anoId, body }) => ({
                url: API_URLS.anoId.replace(':anoId', anoId),
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["ListAnos", "GetAno"]
        }),
        getAno: build.query<IAffiliateNetworkOrder, string>({
            query: (anoId) => API_URLS.anoId.replace(':anoId', anoId),
            providesTags: ["GetAno"]
        }),
        listInvoices: build.query<PaginationResult<IInvoice>, PaginatedQueryPayload>({
            query: ({ params }) => {
                let queryParams = `?page=${params.page}`;
                if (params.searchQuery) {
                    queryParams += `&q=${params.searchQuery}`;
                }
                if (params.field) {
                    queryParams += `&field=${params.field}`;
                }
                if (params.sort) {
                    queryParams += `&sort=${params.sort}`;
                }
                return API_URLS.invoices + queryParams
            },
        }),
        getInvoice: build.query<IInvoice, string>({
            query: (invoiceId) => API_URLS.invoiceId.replace(':invoiceId', invoiceId),
        }),
        listRges: build.query<PaginationResult<IRewardGeneratingEvent>, PaginatedQueryPayload>({
            query: ({ params }) => {
                let queryParams = `?page=${params.page}`;
                if (params.searchQuery) {
                    queryParams += `&q=${params.searchQuery}`;
                }
                if (params.field) {
                    queryParams += `&field=${params.field}`;
                }
                if (params.sort) {
                    queryParams += `&sort=${params.sort}`;
                }
                return API_URLS.rges + queryParams
            },
        }),
        getRge: build.query<IRewardGeneratingEvent, string>({
            query: (rgeId) => API_URLS.rgeId.replace(':rgeId', rgeId),
        }),
    }),
    overrideExisting: false,
})

export const {
    useListAnosQuery,
    useGetAnoQuery,
    useGetInvoiceQuery,
    useListInvoicesQuery,
    useGetRgeQuery,
    useListRgesQuery,
    useUpdateAnoMutation,
} = api