import { Badge, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faArrowAltCircleRight, faArrowDownLong, faArrowUpLong } from '@fortawesome/free-solid-svg-icons';
import { NumericFormat } from 'react-number-format';

type StatWidgetProps = {
    style?: string
    count: number
    headerText: string
    prefix?: string
    icon: IconDefinition
    footerText?: string
    linkTo?: string
    variant?: "primary" | "success" | "warning" | "danger" | "secondary" | "info"
    percent?: number
}

function StatWidget(props: StatWidgetProps) {
    return (
        <Card bg={props.variant ?? 'secondary'} text="white">
            <Card.Header>
                <Row>
                    <Col xs={3}>
                        <FontAwesomeIcon icon={props.icon} size='3x' />
                    </Col>
                    <Col xs={9} className="text-end">
                        <div className="huge">
                            <NumericFormat
                                value={props.count}
                                displayType="text"
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={props.prefix}
                                renderText={formattedValue => <span>{formattedValue}</span>}
                            />
                        </div>
                        <div>{props.headerText} {props.percent ? <Badge bg={props.percent >= 100 ? 'success' : 'danger'}><FontAwesomeIcon icon={props.percent >= 100 ? faArrowUpLong : faArrowDownLong} />%{Math.round(props.percent)}</Badge> : null}</div>
                    </Col>
                </Row>
            </Card.Header>
            {props.linkTo && props.footerText ? <Card.Footer>
                <Link
                    to={
                        props.linkTo // eslint-disable-line
                    }
                    style={{ color: 'white' }}
                >
                    <span className="float-left">{props.footerText}</span>
                    <span className="float-end"><FontAwesomeIcon icon={faArrowAltCircleRight} /></span>
                    <div className="clearfix" />
                </Link>
            </Card.Footer> : null}
        </Card>
    );
}

export default StatWidget;
