import { useParams } from "react-router";
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Breadcrumb, Button, Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { useGetMerchantsCategoryQuery, useUpdateMerchantCategoryMutation } from "../../Store/Merchants/Merchants.service";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import CategoryEditForm, { FormInput } from "./category-edit-form";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";
import { MerchantCategoryUpdatePayload } from "../../Store/Merchants/Types";

function MerchantCategory() {

    const dispatch = useDispatch<AppDispatch>()
    const params = useParams()
    const navigate = useNavigate()

    const args = params.categoryId ? params.categoryId : skipToken
    const merchantCategoryQuery = useGetMerchantsCategoryQuery(args)

    const [updateCategory, updateCategoryResult] = useUpdateMerchantCategoryMutation()

    const handleSubmit = async (body: MerchantCategoryUpdatePayload) => {
        if (merchantCategoryQuery.data?._id) {
            try {
                await updateCategory({
                    categoryId: merchantCategoryQuery.data._id,
                    body,
                }).unwrap()
                dispatch(setMessage({
                    message: 'Merchant category updated successfully',
                    variant: 'success',
                }))
            } catch (err) {
                dispatch(setMessage({
                    message: (err as Error).message,
                    variant: 'danger',
                }))
            }
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/')}>Dash</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/merchants')}>Merchant Categories</Breadcrumb.Item>
                        <Breadcrumb.Item active>{merchantCategoryQuery.data?.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="view">
                <Row className="mb-3">
                    <Col xs={12} md={4}>
                        <Nav variant="pills" className="flex-row">
                            <Nav.Item>
                                <Nav.Link eventKey="view">View</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="edit">Edit</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <Tab.Content>
                            <Tab.Pane eventKey="view">
                                <Card>
                                    <Card.Header>{merchantCategoryQuery.data?.name}</Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={12} md={6}>Svg Image</Col>
                                            <Col xs={12} md={6} className="text-end">
                                                {merchantCategoryQuery.data?.svgXml ? <img src={`data:image/svg+xml;utf8,${encodeURIComponent(merchantCategoryQuery.data?.svgXml)}`} /> : 'No'}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>Filter</Col>
                                            <Col xs={12} md={6} className="text-end">{merchantCategoryQuery.data?.isFilter ? 'Yes' : 'No'}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>Onboarding</Col>
                                            <Col xs={12} md={6} className="text-end">{merchantCategoryQuery.data?.isOnboardingProfile ? 'Yes' : 'No'}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>Weight</Col>
                                            <Col xs={12} md={6} className="text-end">{merchantCategoryQuery.data?.filterWeight}</Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>Impressions</Col>
                                            <Col xs={12} md={6} className="text-end">
                                                <NumericFormat
                                                    value={merchantCategoryQuery.data?.stats?.views ?? 0}
                                                    displayType="text"
                                                    thousandSeparator={true}
                                                    decimalScale={0}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>Recommendations</Col>
                                            <Col xs={12} md={6} className="text-end">
                                                <NumericFormat
                                                    value={merchantCategoryQuery.data?.stats?.recommendations ?? 0}
                                                    displayType="text"
                                                    thousandSeparator={true}
                                                    decimalScale={0}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>Purchases</Col>
                                            <Col xs={12} md={6} className="text-end">
                                                <NumericFormat
                                                    value={merchantCategoryQuery.data?.stats?.txnCount ?? 0}
                                                    displayType="text"
                                                    thousandSeparator={true}
                                                    decimalScale={0}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>Amount</Col>
                                            <Col xs={12} md={6} className="text-end">
                                                <NumericFormat
                                                    value={merchantCategoryQuery.data?.stats?.txnAmount ?? 0}
                                                    displayType="text"
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix="$"
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="edit">
                                {merchantCategoryQuery.data ? <CategoryEditForm
                                    disabled={updateCategoryResult.isLoading || !merchantCategoryQuery.data?._id}
                                    category={merchantCategoryQuery.data}
                                    handleSubmit={handleSubmit}
                                /> : null}
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    );
}

export default MerchantCategory;
