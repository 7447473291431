import { IMerchant } from "@esavvynpm/types";
import { useDeleteMerchantMutation, useListMerchantsQuery } from "../../Store/Merchants/Merchants.service";
import AdvTable from "../AdvTable";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { faCheckCircle, faXmarkCircle, faRotate, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function List() {

    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const [deleteMerchant, deleteMerchantResult] = useDeleteMerchantMutation()

    const onDeleteMerchant = async (merchantId: string) => {
        const confirm = window.confirm(`Delete record ${merchantId}? Cannot be undone!`)
        if (confirm) {
            try {
                await deleteMerchant(merchantId).unwrap()
                dispatch(setMessage({
                    message: 'Merchant deleted successfully',
                    variant: 'success',
                }))
            } catch (err) {
                dispatch(setMessage({
                    message: (err as Error).message,
                    variant: 'danger',
                }))
            }
        }
    }

    return (
        <Row>
            <Col>
                <Row className="mb-3">
                    <Col><Button
                        onClick={() => navigate('/merchants/add')}
                    >Add Merchant</Button></Col>
                </Row>
                <Row>
                    <Col>
                        <AdvTable<IMerchant>
                            queryCallback={useListMerchantsQuery}
                            headers={[{
                                label: 'Name',
                                key: 'name',
                            }, {
                                label: 'Network',
                                key: 'network',
                            }, {
                                label: 'Active',
                                key: 'isActive',
                            }, {
                                label: 'Image',
                                key: 'imageUrl',
                            }, {
                                label: 'Health',
                                key: 'healthCheck',
                                className: 'text-center',
                                disableSort: true,
                            }]}
                            links={[{
                                label: 'View',
                                path: '/merchants/{id}',
                            }]}
                            deleteLink={{
                                callback: onDeleteMerchant,
                                disabled: deleteMerchantResult.isLoading,
                            }}
                            data={{
                                cells: {
                                    isActive: (key, data) => {
                                        return data[key] ? 'Yes' : 'No'
                                    },
                                    imageUrl: (key, data) => {
                                        return data[key] ? 'Yes' : 'No'
                                    },
                                    healthCheck: (key, data) => {
                                        let icon = <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                        if (data.healthCheck) {
                                            if (!data.healthCheck?.isHealthy) {
                                                icon = <FontAwesomeIcon icon={faXmarkCircle} className="text-danger" title={data.healthCheck?.statusText} />
                                            }
                                        } else {
                                            icon = <FontAwesomeIcon icon={faQuestionCircle} className="text-secondary" />
                                        }
                                        if (data.healthCheck?.startDate && !data.healthCheck?.endDate) {
                                            icon = <FontAwesomeIcon icon={faRotate} className="text-secondary fa-spin" />
                                        }
                                        return icon
                                    },
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default List;
