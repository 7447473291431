import { IUser } from "@esavvynpm/types";
import { useDeleteUserMutation, useListUsersQuery } from "../../Store/Users/Users.service";
import AdvTable from "../AdvTable";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";

function List() {

    const dispatch = useDispatch<AppDispatch>()
    const [deleteUser, deleteUserResult] = useDeleteUserMutation()

    const onDeleteUser = async (userId: string) => {
        const confirm = window.confirm(`Delete record ${userId}? Cannot be undone!`)
        if (confirm) {
            try {
                await deleteUser(userId).unwrap()
                dispatch(setMessage({
                    message: 'User deleted successfully',
                    variant: 'success',
                }))
            } catch (err) {
                dispatch(setMessage({
                    message: (err as Error).message,
                    variant: 'danger',
                }))
            }
        }
    }

    return (
        <AdvTable<IUser>
            queryCallback={useListUsersQuery}
            headers={[{
                label: 'Given Name',
                key: 'firstName',
            }, {
                label: 'Surname',
                key: 'lastName',
            }, {
                label: 'Email',
                key: 'email',
            }, {
                label: 'Join Date',
                key: 'createdAt',
                type: "date",
                dateFormat: "short",
            }]}
            links={[{
                label: 'View',
                path: '/users/{id}',
            }]}
            deleteLink={{
                callback: onDeleteUser,
                disabled: deleteUserResult.isLoading,
            }}
        />
    );
}

export default List;
