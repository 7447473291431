import { IMerchantCategory } from "@esavvynpm/types"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

export type FormInput = {
    name: string
    filterWeight: number
    isFilter: boolean
    isOnboardingProfile: boolean
    svgXml: string
}

interface ICategoryEditFormProps {
    handleSubmit(data: FormInput): void
    category: IMerchantCategory
    disabled: boolean
}

const CategoryEditForm: React.FC<ICategoryEditFormProps> = (props: ICategoryEditFormProps) => {

    const initState: FormInput = {
        name: props.category.name,
        filterWeight: props.category.filterWeight ?? 0,
        isFilter: !!props.category.isFilter,
        isOnboardingProfile: !!props.category.isOnboardingProfile,
        svgXml: props.category.svgXml ?? '',
    }

    const [initialValues] = React.useState(initState)

    const onSubmit = (values: FormInput) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        // reValidateMode: "onChange",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="Name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Name"
                    {...register("name", { required: "Name is required" })}
                />
                {errors.name && (
                    <Form.Text className="text-danger">
                        {errors.name.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Filter Weight">
                <Form.Label>Filter Weight</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Filter Weight"
                    {...register("filterWeight")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="IsFilter">
                <Form.Check
                    type="checkbox"
                    label="IsFilter"
                    {...register("isFilter")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="IsOnboardingProfile">
                <Form.Check
                    type="checkbox"
                    label="IsOnboardingProfile"
                    {...register("isOnboardingProfile")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Svg Xml">
                <Form.Label>Svg Xml</Form.Label>
                <Form.Control
                    type="text"
                    as="textarea"
                    rows={4}
                    placeholder="Svg Xml"
                    {...register("svgXml")}
                />
            </Form.Group>

            <Button
                style={{ marginTop: 10 }}
                type='submit'
                variant="primary"
                disabled={!isValid || props.disabled}
            >Save</Button>
        </Form>

    )
}

export default CategoryEditForm