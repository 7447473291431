import Alert from "react-bootstrap/Alert"
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/Store";
import { useEffect, useState } from "react";
import { hideMessage } from "../../Store/Notification/Notification.slice";
import { Col, Container, Row } from "react-bootstrap";

const TIMEOUT = 5000 // hide message after 5 sec

function Notification() {

    const dispatch = useDispatch<AppDispatch>()
    const message = useSelector((state: RootState) => state.notification.message)
    const variant = useSelector((state: RootState) => state.notification.variant)
    const show = useSelector((state: RootState) => state.notification.show)
    const [visible, setVisibility] = useState(false)

    useEffect(() => {
        if (show) {
            setVisibility(true)
            setTimeout(() => {
                dispatch(hideMessage())
            }, TIMEOUT)
        } else {
            setVisibility(false)
        }
    }, [dispatch, show])

    return (
        <>
            {!!visible ? <Container>
                <Row>
                    <Col xs={12}>
                        <Alert
                            key={variant}
                            variant={variant}
                        >
                            {message}
                        </Alert>
                    </Col>
                </Row>
            </Container> : null}
        </>
    );
}

export default Notification;
