import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { useAddMerchantCategoryMutation } from "../../Store/Merchants/Merchants.service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";
import { MerchantCategoryUpdatePayload } from "../../Store/Merchants/Types";
import CategoryAddForm from "./category-add-form";

function MerchantCategoryAdd() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const [addCategory, addCategoryResult] = useAddMerchantCategoryMutation()

    const handleSubmit = async (body: MerchantCategoryUpdatePayload) => {
        try {
            await addCategory(body).unwrap()
            dispatch(setMessage({
                message: 'Merchant category added successfully',
                variant: 'success',
            }))
            navigate('/merchants')
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/')}>Dash</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/merchants')}>Merchant Categories</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12}>
                    <h1>Add Merchant Category</h1>
                    <hr />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} md={4}>
                    <CategoryAddForm
                        disabled={addCategoryResult.isLoading}
                        handleSubmit={handleSubmit}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default MerchantCategoryAdd;
