import { IMerchantScrap } from "@esavvynpm/types";
import { Offcanvas } from "react-bootstrap";
import MerchantScrapItemEditForm, { FormInput } from "./merchant-scrap-item-edit-form";
import { useUpdateMerchantScrapMutation } from "../../Store/Merchants/MerchantScrap.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";

function MerchantScrapItemEdit(props: {
    merchantScrapEdit: IMerchantScrap
    handleClose: () => void
}) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateMerchantScrap] = useUpdateMerchantScrapMutation()

    const handleSubmit = async (data: FormInput) => {
        try {
            await updateMerchantScrap({
                ...data,
                merchantScrapId: (props.merchantScrapEdit as any)._id,
            }).unwrap()
            dispatch(setMessage({
                message: 'Merchant scrap updated successfully',
                variant: 'success',
            }))
            props.handleClose()
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return (
        <Offcanvas
            show={!!props.merchantScrapEdit}
            onHide={props.handleClose}
            scroll={false}
            backdrop={true}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Edit {props.merchantScrapEdit?.title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <MerchantScrapItemEditForm
                    disabled={false}
                    handleSubmit={handleSubmit}
                    merchantScrap={props.merchantScrapEdit}
                />
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default MerchantScrapItemEdit;
