import { IUser } from "@esavvynpm/types";
import { useGetUserHistoryQuery } from "../../Store/Users/Users.service";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { DateService } from "../../Services/date";
import moment from "moment";

function UserHistory(props: { user: IUser }) {

    const [isFetching, setIsFetching] = useState(false)
    const [history, setHistory] = useState('')
    const [dateRange, setDateRange] = useState({
        startDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    })
    const influencerProfileQuery = useGetUserHistoryQuery({
        userId: props.user._id,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    })

    useEffect(() => {
        if (influencerProfileQuery.isSuccess && !influencerProfileQuery.isLoading && !influencerProfileQuery.isFetching) {
            const historyTmp: string[] = history.split("\n")
            Object.keys(influencerProfileQuery.data).forEach(date => {
                historyTmp.push(`${DateService.formatDate(date, 'short')}:\t${influencerProfileQuery.data[date]}`)
            })
            setHistory(historyTmp.join("\n"))
        }
        setIsFetching(influencerProfileQuery.isLoading || influencerProfileQuery.isFetching)
    }, [
        influencerProfileQuery.isSuccess,
        influencerProfileQuery.isLoading,
        influencerProfileQuery.isFetching,
        influencerProfileQuery.data,
        history,
    ])

    return (
        <>
            {!history && !influencerProfileQuery.isLoading ? 'No history' : ''}
            {influencerProfileQuery.isLoading ? 'Loading...' : ''}
            {!influencerProfileQuery.isLoading && history ? <>
                <h3>History</h3>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="text"
                        as="textarea"
                        readOnly={true}
                        value={history + (influencerProfileQuery.isLoading || influencerProfileQuery.isFetching ? `\nLoading date range ${dateRange.startDate} - ${dateRange.endDate}...` : '')}
                        rows={30}
                        onScroll={e => {
                            if (!isFetching) {
                                const div = e.target as HTMLDivElement
                                const bottom = div.scrollHeight - div.scrollTop <= div.clientHeight + 100;
                                if (bottom) {
                                    setIsFetching(true)
                                    const startDate = moment(dateRange.startDate).subtract(1, 'month').format('YYYY-MM-DD')
                                    const endDate = dateRange.startDate
                                    setDateRange({
                                        startDate,
                                        endDate,
                                    })
                                }
                            }
                        }}
                    />
                </Form.Group>
            </> : null}
        </>
    );
}

export default UserHistory;
