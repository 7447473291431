import { Container } from "react-bootstrap";
import NavPage from "../NavPage";
import List from "./list";
import Overview from "./overview";
import Recommendations from "./recommendations";

function Users() {

    return (
        <Container fluid>
            <NavPage
                pageTitle="Users"
                items={[{
                    label: 'Overview',
                    key: '/users',
                }, {
                    label: 'Users',
                    key: '/users/list',
                }, {
                    label: 'Recommendations',
                    key: '/recommendations',
                }]}
                children={{
                    '/users': <Overview />,
                    '/users/list': <List />,
                    '/recommendations': <Recommendations />,
                }}
            />
        </Container>
    );
}

export default Users;
