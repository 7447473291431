import { AuthResponse } from "@esavvynpm/types"

export type AuthorizationContainerState = {
    isInit: boolean
    isLoggedIn: boolean
    screen: AuthScreens
    isAuthenticatingToken: boolean
    user: AuthResponse | null
    isAuthenticating: boolean
    isAuthenticationError: boolean
    authenticationError: string | null
    isLoggingOut: boolean
    logoutError: string | null
}
export interface IAuthTokenPayload {
    token: string
}

export interface ISetUserNotificationOpenDatePayload {
    notificationId: string
}

export interface ISignInWithEsavvyPayload {
    email: string
    password: string
}

export interface ISignInWithGooglePayload {
    idToken: string
}

export interface ISignInWithFacebookPayload {
    accessToken: string
}

export interface ISignInWithApplePayload {
    idToken: string
}

export interface ISignUpWithGooglePayload {
    idToken: string
}

export interface ISignUpWithFacebookPayload {
    accessToken: string
}

export interface ISignUpWithApplePayload {
    idToken: string
}

export interface ISignUpWithEsavvyPayload {
    firstName: string
    lastName: string
    email: string
    password: string
    phone: string
    countryCode: string
    dob: string
}

export enum AuthScreens {
    SIGN_IN = 'SIGN_IN',
    SIGN_UP = 'SIGN_UP',
    EULA = 'EULA',
    PP = 'PP',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    SPLASH = 'SPLASH',
    INTRO_SLIDER = 'INTRO_SLIDER',
}