import { IFeedProduct } from "@esavvynpm/types";
import AdvTable from "../AdvTable";
import { useListFeedProductsQuery } from "../../Store/Feed/Feed.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FeedList() {

    const dispatch = useDispatch<AppDispatch>()

    const copyToClipboard = (text?: string) => {
        if (text) {
            navigator.clipboard.writeText(text)
            dispatch(setMessage({
                message: 'Copied to clipboard.',
                variant: 'success',
            }))
        }
    }

    return (
        <AdvTable<IFeedProduct & {
            "merchant.name": string
            "stats.likes": number
            "stats.views": number
            "stats.recommendations": number
            "stats.shares": number
            "stats.transactions": number
            "stats.commission": number
        }>
            queryCallback={useListFeedProductsQuery}
            headers={[{
                label: 'Date',
                key: 'createdAt',
                dateFormat: 'short',
                type: 'date',
            }, {
                label: 'Title',
                key: 'title',
            }, {
                label: 'Merchant',
                key: 'merchant.name',
            }, {
                label: 'Likes',
                key: 'stats.likes',
                className: 'text-end',
            }, {
                label: 'Views',
                key: 'stats.views',
                className: 'text-end',
            }, {
                label: 'Recs',
                key: 'stats.recommendations',
                className: 'text-end',
            }, {
                label: 'Shares',
                key: 'stats.shares',
                className: 'text-end',
            }, {
                label: 'Txn',
                key: 'stats.transactions',
                className: 'text-end',
            }, {
                label: '$',
                key: 'stats.commission',
                className: 'text-end',
            }]}
            links={[{
                label: 'View',
                path: '/feed-products/{id}',
            }]}
            data={{
                cells: {
                    title: (key, data) => {
                        return <>
                            <div>{data.title}</div>
                            <div className="small text-secondary"><FontAwesomeIcon icon={faCopy} style={{cursor: 'pointer'}} onClick={() => copyToClipboard(data._id)} /> {data._id}</div>
                        </>
                    },
                    "merchant.name": (key, data) => {
                        return `${data.merchant.name} (${data.merchant.network})`
                    },
                    "stats.likes": (key, data) => {
                        return data.stats?.likes?.toString() ?? ''
                    },
                    "stats.views": (key, data) => {
                        return data.stats?.views?.toString() ?? ''
                    },
                    "stats.recommendations": (key, data) => {
                        return data.stats?.recommendations?.toString() ?? ''
                    },
                    "stats.shares": (key, data) => {
                        return data.stats?.shares?.toString() ?? ''
                    },
                    "stats.transactions": (key, data) => {
                        return data.stats?.transactions?.toString() ?? ''
                    },
                    "stats.commission": (key, data) => {
                        return data.stats?.commission ? `$${data.stats.commission.toFixed(2)}` : ''
                    },
                }
            }}
            filters={[{
                id: 'has-txns',
                label: 'Has Txns',
                field: 'stats.transactions',
                filter: {
                    $gt: 0,
                }
            }]}
        />
    );
}

export default FeedList;
