import { Badge, Breadcrumb, Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetRgeQuery } from "../../Store/Income/Income.service";
import { skipToken } from "@reduxjs/toolkit/query";
import { DateService } from "../../Services/date";
import { useListMerchantsCompleteQuery } from "../../Store/Merchants/Merchants.service";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/Store";
import { NumericFormat } from "react-number-format";

function RewardGeneratingEvent() {

    const params = useParams()
    const navigate = useNavigate()

    const args = params.rgeId ? params.rgeId : skipToken
    const rgeQuery = useGetRgeQuery(args)

    const merchantsQuery = useListMerchantsCompleteQuery()
    const merchantKeys = useSelector((state: RootState) => state.merchants.merchantKeys)

    const isLoading = rgeQuery.isLoading || merchantsQuery.isLoading

    const merchantName = merchantKeys && rgeQuery.data?.data.merchantId ? merchantKeys[rgeQuery.data.data.merchantId].name : null

    const statusBadgeText = rgeQuery.data?.status === "PENDING" ? 'dark' : undefined
    const statusBadgeBg = rgeQuery.data?.status === "CONFIRMED" ? 'success' : rgeQuery.data?.status === "PENDING" ? 'warning' : 'danger'

    return (
        <Container fluid>
            {isLoading ? <>
                <Row>
                    <Col xs={12}>Loading...</Col>
                </Row>
            </> : <Tab.Container id="left-tabs-example" defaultActiveKey="view">
                <Row className="mb-3">
                    <Col xs={12}>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate('/')}>Dash</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate('/income')}>Income</Breadcrumb.Item>
                            <Breadcrumb.Item active>{rgeQuery.data?._id}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12}>
                        <Nav variant="pills" className="flex-row">
                            <Nav.Item>
                                <Nav.Link eventKey="view">View</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="edit">Edit</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="view">
                                <Row>
                                    <Col className="mb-3" xs={12}>
                                        <Card>
                                            <Card.Header>RGE from {merchantName}</Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={12} md={6}>ID</Col>
                                                    <Col xs={12} md={6} className="text-end">{rgeQuery.data?._id}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>AURL</Col>
                                                    <Col xs={12} md={6} className="text-end">{rgeQuery.data?.data.aurlId}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Created</Col>
                                                    <Col xs={12} md={6} className="text-end">{DateService.formatDate(rgeQuery.data?.createdAt, 'short')}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Status</Col>
                                                    <Col xs={12} md={6} className="text-end">
                                                        <Badge text={statusBadgeText} bg={statusBadgeBg}>{rgeQuery.data?.status}</Badge>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}><hr /></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Merchant</Col>
                                                    <Col xs={12} md={6} className="text-end"><Link to={'/merchants/' + rgeQuery.data?.data.merchantId}>{merchantName}</Link></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>User</Col>
                                                    <Col xs={12} md={6} className="text-end"><Link to={'/users/' + rgeQuery.data?.userId}>{rgeQuery.data?.userId}</Link></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}><hr /></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Affiliate Order ID</Col>
                                                    <Col xs={12} md={6} className="text-end">{rgeQuery.data?.affiliateOrderId}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Invoice</Col>
                                                    <Col xs={12} md={6} className="text-end"><Link to={'/invoices/' + rgeQuery.data?.invoiceId}>{rgeQuery.data?.invoiceId}</Link></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}><hr /></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Type</Col>
                                                    <Col xs={12} md={6} className="text-end">{rgeQuery.data?.type}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Commission</Col>
                                                    <Col xs={12} md={6} className="text-end">
                                                        <NumericFormat
                                                            value={rgeQuery.data?.amount}
                                                            displayType="text"
                                                            thousandSeparator={true}
                                                            prefix="$"
                                                            decimalScale={2}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Original Commission</Col>
                                                    <Col xs={12} md={6} className="text-end">
                                                        <NumericFormat
                                                            value={rgeQuery.data?.originalAmount}
                                                            displayType="text"
                                                            thousandSeparator={true}
                                                            prefix={rgeQuery.data?.originalCurrency}
                                                            decimalScale={2}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Currency Rate</Col>
                                                    <Col xs={12} md={6} className="text-end">{rgeQuery.data?.originalCurrencyRate}</Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>}
        </Container>
    );
}

export default RewardGeneratingEvent;
