import { IAffiliateNetworkOrder } from "@esavvynpm/types";
import AdvTable from "../AdvTable";
import { useListAnosQuery } from "../../Store/Income/Income.service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/Store";
import { useListMerchantsCompleteQuery } from "../../Store/Merchants/Merchants.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { setMessage } from "../../Store/Notification/Notification.slice";

function ListAnos() {

    const dispatch = useDispatch<AppDispatch>()
    useListMerchantsCompleteQuery()
    const merchantKeys = useSelector((state: RootState) => state.merchants.merchantKeys)

    const copyToClipboard = (text?: string) => {
        if (text) {
            navigator.clipboard.writeText(text)
            dispatch(setMessage({
                message: 'Copied to clipboard.',
                variant: 'success',
            }))
        }
    }

    return (
        <>
            {merchantKeys ? <AdvTable<IAffiliateNetworkOrder>
                queryCallback={useListAnosQuery}
                headers={[{
                    label: 'Date',
                    key: 'createdAt',
                    dateFormat: 'short',
                    type: 'date',
                }, {
                    label: 'Network',
                    key: 'networkId',
                }, {
                    label: 'Merchant',
                    key: 'merchantId',
                }, {
                    label: 'Status',
                    key: 'status',
                }, {
                    label: 'Commission',
                    key: 'commission',
                    className: 'text-end'
                }, {
                    label: 'AURL',
                    key: 'aurlId',
                }, {
                    label: 'Invoice',
                    key: 'affiliateInvoiceId',
                }, {
                    label: 'Feed',
                    key: 'sourceFeedItem',
                }]}
                links={[{
                    label: 'View',
                    path: '/anos/{id}',
                }]}
                data={{
                    cells: {
                        sourceFeedItem: (key, data) => {
                            return data[key] ? 'Yes' : 'No'
                        },
                        commission: (key, data) => {
                            return data[key] ? `$${data[key].toFixed(2)}` : ''
                        },
                        merchantId: (key, data) => {
                            if (!data['merchantId']) {
                                return ''
                            }
                            return <>
                                <FontAwesomeIcon icon={faCopy} className="text-secondary" onClick={() => copyToClipboard(data['merchantId'])} />
                                <Link to={'/merchants/' + data['merchantId']}>{merchantKeys[data['merchantId']].name}</Link>
                            </>
                        },
                        aurlId: (key, data) => {
                            return data['aurlId'] ? 'Yes' : 'No'
                        },
                        affiliateInvoiceId: (key, data) => {
                            return data['affiliateInvoiceId'] ? 'Yes' : 'No'
                        },
                    }
                }}
                filters={[{
                    id: 'empty-aurl',
                    label: 'Empty AURL',
                    field: 'aurlId',
                    filter: {
                        $exists: false,
                    }
                }, {
                    id: 'valid-aurl',
                    label: 'Valid AURL',
                    field: 'aurlId',
                    filter: {
                        $exists: true,
                    }
                }, {
                    id: 'empty-invoice',
                    label: 'No invoice',
                    field: 'affiliateInvoiceId',
                    filter: {
                        $exists: false,
                    }
                }, {
                    id: 'valid-invoice',
                    label: 'Valid invoice',
                    field: 'affiliateInvoiceId',
                    filter: {
                        $exists: true,
                    }
                }, {
                    id: 'from-feed',
                    label: 'From feed',
                    field: 'sourceFeedItem',
                    filter: {
                        $exists: true,
                    }
                }, {
                    id: 'status-complete',
                    label: 'Status: Complete',
                    field: 'status',
                    filter: 'COMPLETED'
                }, {
                    id: 'status-pending',
                    label: 'Status: Pending',
                    field: 'status',
                    filter: 'PENDING'
                }, {
                    id: 'status-rejected',
                    label: 'Status: Rejected',
                    field: 'status',
                    filter: 'REJECTED'
                }]}
                customAggregation={{
                    label: 'Total Commission',
                    prefix: "$",
                    toFixed: 2,
                }}
            /> : null}
        </>
    );
}

export default ListAnos;
