import { Card, Col, Row } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import { useListInvoicesQuery } from "../../Store/Income/Income.service";

function AffiliateNetworkOrderInvoice(props: {
    orderId: string
}) {
    const relatedInvoicesQuery = useListInvoicesQuery({
        params: {
            searchQuery: 'invoiceOrderId:' + props.orderId,
            page: 1,
        }
    })

    const relatedInvoices = relatedInvoicesQuery.data ? relatedInvoicesQuery.data.docs : []

    return (
        <Row className="mb-3">
            <Col>
                <Card>
                    <Card.Header>Related Invoices</Card.Header>
                    <Card.Body>
                        {relatedInvoices.map((invoice, index) => <>
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={12} md={6}>ID</Col>
                                        <Col xs={12} md={6} className="text-end"><Link to={'/invoices/' + invoice._id}>{invoice._id}</Link></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Type</Col>
                                        <Col xs={12} md={6} className="text-end">{invoice.invoiceServiceType}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Commission</Col>
                                        <Col xs={12} md={6} className="text-end">
                                            <NumericFormat
                                                value={invoice.invoiceAmount}
                                                displayType="text"
                                                thousandSeparator={true}
                                                prefix="$"
                                                decimalScale={2}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {index < relatedInvoices.length - 1 ? <Row>
                                <Col xs={12}><hr /></Col>
                            </Row> : null}
                        </>)}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default AffiliateNetworkOrderInvoice;
