import { IMerchant, IMerchantCategory, IMerchantHealthStatus, IMerchantTerms, PaginationResult } from "@esavvynpm/types"
import { baseSplitApi } from "../../Services/BaseApi"
import { API_URLS } from "../../Services/api-urls"
import { PaginatedQueryPayload } from "../Types"
import { MerchantAddPayload, MerchantCategoryUpdatePayload, MerchantUpdatePayload } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listMerchants: build.query<PaginationResult<IMerchant>, PaginatedQueryPayload>({
            query: ({ id, idKey, params }) => {
                let queryParams = `?page=${params.page}`
                if (params.searchQuery) {
                    queryParams += `&q=${params.searchQuery}`
                }
                if (params.field) {
                    queryParams += `&field=${params.field}`
                }
                if (params.sort) {
                    queryParams += `&sort=${params.sort}`
                }
                return API_URLS.listMerchantsPaginated + queryParams
            },
            providesTags: ["ListMerchants"],
        }),
        listMerchantsComplete: build.query<IMerchant[], void>({
            query: () => API_URLS.listMerchantsComplete,
            providesTags: ["ListMerchants"],
        }),
        listMerchantsCategories: build.query<IMerchantCategory[], void>({
            query: () => API_URLS.listMerchantsCategories,
            providesTags: ["ListMerchantCategories"],
        }),
        getMerchantsCategory: build.query<IMerchantCategory, string>({
            query: (categoryId) => API_URLS.merchantsCategory.replace(':categoryId', categoryId),
            providesTags: ["GetMerchantCategory"],
        }),
        addMerchantCategory: build.mutation<void, MerchantCategoryUpdatePayload>({
            query: (body) => ({
                url: API_URLS.listMerchantsCategories,
                method: 'POST',
                body,
            }),
            invalidatesTags: ["ListMerchantCategories"]
        }),
        updateMerchantCategory: build.mutation<void, { categoryId: string, body: MerchantCategoryUpdatePayload }>({
            query: ({ categoryId, body }) => ({
                url: API_URLS.merchantsCategory.replace(':categoryId', categoryId),
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["ListMerchantCategories", "GetMerchantCategory"]
        }),
        getMerchant: build.query<IMerchant, string>({
            query: (merchantId) => API_URLS.merchantId.replace(':merchantId', merchantId),
            providesTags: ["GetMerchant"]
        }),
        getMerchantTerms: build.query<IMerchantTerms, string>({
            query: (merchantId) => API_URLS.merchantIdTerms.replace(':merchantId', merchantId),
            providesTags: ["GetMerchantTerms"]
        }),
        updateMerchantTerms: build.mutation<void, {
            merchantId: string, body: {
                geoInclude?: string[]
                geoExclude?: string[]
                terms?: string
                isActive: boolean
            }
        }>({
            query: ({ merchantId, body }) => ({
                url: API_URLS.merchantIdTerms.replace(':merchantId', merchantId),
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["GetMerchantTerms"]
        }),
        addMerchant: build.mutation<void, MerchantAddPayload>({
            query: (body) => ({
                url: API_URLS.merchants,
                method: 'POST',
                body,
            }),
            invalidatesTags: ["ListMerchants"]
        }),
        updateMerchant: build.mutation<void, { merchantId: string, body: MerchantUpdatePayload }>({
            query: ({ merchantId, body }) => ({
                url: API_URLS.merchantId.replace(':merchantId', merchantId),
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["ListMerchants", "GetMerchant"]
        }),
        deleteMerchant: build.mutation<void, string>({
            query: (merchantId) => ({
                url: API_URLS.merchantId.replace(':merchantId', merchantId),
                method: 'DELETE',
            }),
            invalidatesTags: ["ListMerchants", "GetMerchant"]
        }),
        importMerchantScraps: build.mutation<{ counter: number }, void>({
            query: () => ({
                url: API_URLS.merchantScrapsImport,
                method: 'GET',
            }),
            invalidatesTags: ["ListMerchants", "GetMerchant"]
        }),
        getMerchantHealthStatus: build.query<IMerchantHealthStatus, string>({
            query: (merchantId) => API_URLS.merchantHealthStatus.replace(':merchantId', merchantId),
        }),
        getMerchantHealthStatusScreenshot: build.mutation<{ imageBase64: string }, string>({
            query: (merchantId) => ({
                url: API_URLS.merchantHealthStatusScreenshot.replace(':merchantId', merchantId),
                method: 'GET',
            }),
        }),
        listNetworks: build.query<string[], void>({
            query: () => API_URLS.networks
        }),
    }),
    overrideExisting: false,
})

export const {
    useListMerchantsQuery,
    useDeleteMerchantMutation,
    useGetMerchantQuery,
    useAddMerchantMutation,
    useListMerchantsCategoriesQuery,
    useAddMerchantCategoryMutation,
    useUpdateMerchantCategoryMutation,
    useGetMerchantsCategoryQuery,
    useGetMerchantTermsQuery,
    useUpdateMerchantTermsMutation,
    useUpdateMerchantMutation,
    useListMerchantsCompleteQuery,
    useImportMerchantScrapsMutation,
    useGetMerchantHealthStatusQuery,
    useGetMerchantHealthStatusScreenshotMutation,
    useListNetworksQuery,
} = api