import App from './app/App';
import './App.css';

function AppRoot() {
  return (
    <App />
  );
}

export default AppRoot;
