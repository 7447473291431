import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { BookingTxn } from "../../Store/Booking/Types";
import { usePapaParse } from 'react-papaparse';
import BookingCsvParser from "./booking-csv-parser.class";
import { useImportBookingTxnMutation } from "../../Store/Booking/Booking.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";

type CsvParseResult = { data?: string[][] }

function BookingTxnImport() {

    const { readString } = usePapaParse();
    const dispatch = useDispatch<AppDispatch>()
    const [csv, setCsv] = useState<string | ArrayBuffer | null>(null)
    const [orders, setOrders] = useState<BookingTxn[] | null>(null)
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [importBookingTxn] = useImportBookingTxnMutation()

    const generateTxnsFromCSV = useCallback((data?: string[][]) => {
        if (data) {
            const orders: BookingTxn[] = []
            for (const row of data) {
                const parser = new BookingCsvParser(row)
                const order = parser.getOrder()
                orders.push(order)
            }
            setOrders(orders)
            setCsv(null)
        }
    }, [setOrders, setCsv]);

    const onCsvDataUpdate = useCallback((csvData: CsvParseResult) => {
        if (csvData.data) {
            csvData.data.shift()
            generateTxnsFromCSV(csvData.data)
        }
    }, [generateTxnsFromCSV]);

    const handleReadString = useCallback((csv: string) => {
        readString(csv, {
            worker: true,
            complete: (results: CsvParseResult) => {
                onCsvDataUpdate(results)
            },
        });
    }, [readString, onCsvDataUpdate]);

    useEffect(() => {
        if (csv) {
            handleReadString(csv.toString())
        }
    }, [csv, handleReadString])

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let text = await getFileText(e.target.files[0]);
            if (text) {
                text = text.toString()
                setCsv(text)
            } else {
                console.error('Empty file')
            }
        }
    }

    const getFileText = (file: File) => {
        return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
            let text: string | ArrayBuffer | null = '';
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsText(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                text = reader.result;
                resolve(text);
            };
            reader.onerror = (event: any) => {
                reject(event)
            }
        });
    }

    const onImportClick = async () => {
        if (orders) {
            const confirm = window.confirm(`Import ${orders.length} transactions?`)
            if (confirm) {
                try {
                    await importBookingTxn(orders).unwrap()
                    dispatch(setMessage({
                        message: 'Booking transactions imported successfully',
                        variant: 'success',
                    }))
                    clearForm()
                } catch (err) {
                    dispatch(setMessage({
                        message: (err as Error).message,
                        variant: 'danger',
                    }))
                }
            }
        }
    }

    const renderBookingFormattedTxnTable = () => {
        if (orders) {
            let headers: string[] = Object.keys(orders[0])
            const body = orders
            return (
                <>
                    <Button
                        variant={'primary'}
                        disabled={!orders}
                        onClick={onImportClick}
                    >
                        Import
                    </Button>
                    <Table>
                        <thead>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={`header${index}`}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {body?.map((row: BookingTxn, rowIndex: number) => (
                                <tr key={`row${rowIndex}`}>
                                    {headers.map((header, cellIndex) => (
                                        <td key={`val${cellIndex}`}>{(row as any)[header]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            )
        }
    }

    const clearForm = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = ''
            setCsv(null)
            setOrders(null)
        }
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Header>Import Booking Transactions</Card.Header>
                <Card.Body>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload CSV with Booking transactions</Form.Label>
                        <Form.Control ref={inputRef} type="file" onChange={handleFileChange} />
                    </Form.Group>
                    <Button
                        variant="secondary"
                        disabled={!csv && !orders}
                        onClick={clearForm}
                    >Clear</Button>
                </Card.Body>
            </Card>
            {orders?.length ? <Card>
                <Card.Header>Pending Import Transactions</Card.Header>
                <Card.Body>
                    {renderBookingFormattedTxnTable()}
                </Card.Body>
            </Card> : null}
        </>
    );
}

export default BookingTxnImport;
