import { Container } from "react-bootstrap";
import NavPage from "../NavPage";
import ListAnos from "./list-anos";
import ListInvoices from "./list-invoices";
import ListRges from "./list-rges";
import StatsAccounting from "./stats-accounting";

function Income() {

    return (
        <Container fluid>
            <NavPage
                pageTitle="Income"
                items={[{
                    label: 'Accounting',
                    key: '/stats-accounting',
                }, {
                    label: 'Affiliate Network Txns',
                    key: '/anos',
                }, {
                    label: 'Invoices',
                    key: '/invoices',
                }, {
                    label: 'Rge',
                    key: '/rges',
                }]}
                children={{
                    '/stats-accounting': <StatsAccounting />,
                    '/anos': <ListAnos />,
                    '/invoices': <ListInvoices />,
                    '/rges': <ListRges />,
                }}
            />
        </Container>
    );
}

export default Income;
