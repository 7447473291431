import moment from "moment"

export type DateFormatType = 'basic' | 'short' | 'long'

interface IDateService {
    formatDate(stringDate?: string | Date, format?: DateFormatType): string | null
}

export const DateService: IDateService = {
    formatDate,
}

function formatDate(stringDate?: string, format?: DateFormatType): string | null {
    if (!stringDate) {
        return null
    }

    const dateObj = moment(stringDate)
    let f = format ?? 'basic'

    switch (f) {
        case 'basic':
            return dateObj.format('DD/MM/YYYY')
        case 'short':
            return dateObj.format('DD/MM/YYYY HH:mm:SS')
        case 'long':
            return dateObj.format('LLLL')
    }
}