import { IAffiliateNetworkOrder } from "@esavvynpm/types"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

export type FormInput = {
    commission: number
    originalCommission: number
    currency: string
    currencyRate: number
}

interface IAnoEditFormProps {
    handleSubmit(data: FormInput): void
    ano: IAffiliateNetworkOrder
    disabled: boolean
}

const AnoEditForm: React.FC<IAnoEditFormProps> = (props: IAnoEditFormProps) => {

    const initState: FormInput = {
        commission: props.ano.commission,
        originalCommission: props.ano.originalCommission,
        currency: props.ano.currency,
        currencyRate: props.ano.currencyRate,
    }

    const [initialValues] = React.useState(initState)

    const onSubmit = (values: FormInput) => {
        props.handleSubmit({
            commission: +values.commission,
            originalCommission: +values.originalCommission,
            currency: values.currency,
            currencyRate: +values.currencyRate,
        })
    }

    const {
        register,
        handleSubmit,
        formState: { isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="Commission">
                <Form.Label>Commission</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Commission"
                    {...register("commission")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="OriginalCommission">
                <Form.Label>Original Commission</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Original Commission"
                    {...register("originalCommission")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="OriginalCurrency">
                <Form.Label>Original Currency</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Original Currency"
                    {...register("currency")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CurrencyRate">
                <Form.Label>Currency Rate</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Currency Rate"
                    {...register("currencyRate")}
                />
            </Form.Group>

            <Button
                style={{ marginTop: 10 }}
                type='submit'
                variant="primary"
                disabled={!isValid || props.disabled}
            >Save</Button>
        </Form>

    )
}

export default AnoEditForm