import { Col, Row } from "react-bootstrap";
import FeedStats from "./feed-stats";
import FeedList from "./feed-list";

function Overview() {

    return (
        <>
            <Row>
                <Col xs={12} md={9}>
                    <FeedList />
                </Col>
                <Col xs={12} md={3}>
                    <FeedStats />
                </Col>
            </Row>
        </>
    );
}

export default Overview;
