import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { useAddMerchantMutation, useListMerchantsCategoriesQuery } from "../../Store/Merchants/Merchants.service";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";
import MerchantAddForm from "./merchant-profile-add-form";
import { MerchantAddPayload } from "../../Store/Merchants/Types";

function MerchantAdd() {

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const [addMerchant, addMerchantResult] = useAddMerchantMutation()

    const merchantCategoriesQuery = useListMerchantsCategoriesQuery()

    const handleSubmit = async (body: MerchantAddPayload) => {
        try {
            await addMerchant(body).unwrap()
            dispatch(setMessage({
                message: 'Merchant category added successfully',
                variant: 'success',
            }))
            navigate('/merchants')
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate('/')}>Dash</Breadcrumb.Item>
                        <Breadcrumb.Item onClick={() => navigate('/merchants')}>Merchants</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12}>
                    <h1>Add Merchant</h1>
                    <hr />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} md={4}>
                    {merchantCategoriesQuery.data ? <MerchantAddForm
                        disabled={addMerchantResult.isLoading}
                        handleSubmit={handleSubmit}
                        merchantCategories={merchantCategoriesQuery.data}
                    /> : null}
                </Col>
            </Row>
        </Container>
    );
}

export default MerchantAdd;
