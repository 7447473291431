import { IMerchantScrap } from "@esavvynpm/types";
import { Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";
import MerchantScrapItemScheduleForm, { FormInput } from "./merchant-scrap-item-schedule-form";
import { usePublishScheduledFeedProductMutation } from "../../Store/Feed/Feed.service";
import { useUpdateMerchantScrapMutation } from "../../Store/Merchants/MerchantScrap.service";

function MerchantScrapItemSchedule(props: {
    merchantScrapSchedule: IMerchantScrap
    handleClose: () => void
}) {

    const dispatch = useDispatch<AppDispatch>()
    const [publish] = usePublishScheduledFeedProductMutation()
    const [updateMerchantScrap] = useUpdateMerchantScrapMutation()

    const handleSubmit = async (data: FormInput) => {
        try {
            const scheduledFeedItem = await publish({
                ...data,
                publishAt: data.publishAt.toUTCString(),
            }).unwrap()
            await updateMerchantScrap({
                merchantScrapId: (props.merchantScrapSchedule as any)._id,
                scheduledFeedProductId: scheduledFeedItem._id,
            }).unwrap()
            dispatch(setMessage({
                message: 'Scheduled feed product published successfully',
                variant: 'success',
            }))

            props.handleClose()
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return (
        <Offcanvas
            show={!!props.merchantScrapSchedule}
            onHide={props.handleClose}
            scroll={false}
            backdrop={true}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Schedule {props.merchantScrapSchedule?.title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <MerchantScrapItemScheduleForm
                    disabled={false}
                    handleSubmit={handleSubmit}
                    merchantScrap={props.merchantScrapSchedule}
                />
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default MerchantScrapItemSchedule;
