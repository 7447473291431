export const API_URLS = {
    healthz: '/healthz',
    adminAuthToken: '/admin-users/auth-token',
    adminAuthEsavvy: '/admin-users/auth',
    listUsers: '/users',
    userId: '/users/:userId',
    userIdInfluencer: '/users/:userId/influencer',
    userIdHistory: '/users/:userId/history',
    userIdRecommendations: '/users/:userId/recommendations',
    merchants: '/merchants',
    listMerchantsComplete: '/merchants/complete',
    listMerchantsCategories: '/merchants/categories',
    merchantsCategory: '/merchants/categories/:categoryId',
    listMerchantsPaginated: '/merchants/paginated',
    merchantId: '/merchants/:merchantId',
    merchantIdTerms: '/merchants/:merchantId/terms',
    listMerchantScrap: '/merchant-scraps',
    merchantScrapId: '/merchant-scraps/:merchantScrapId',
    merchantScrapsImport: '/merchant-scraps/import',
    merchantHealthStatus: '/merchant-health-status/:merchantId',
    merchantHealthStatusScreenshot: '/merchant-health-status/:merchantId/screenshot',
    statsIncomeByType: '/stats/income-by-type',
    statsFeed: '/stats/feed',
    statsAccounting: '/stats/accounting',
    feedProductsPaginated: '/feed-products/paginated',
    feedProductsRegionPaginated: '/feed-products-region/paginated',
    regions: '/regions',
    scheduledFeedProducts: '/scheduled-feed-products',
    scheduledFeedProductId: '/scheduled-feed-products/:productId',
    scheduledFeedProductsPaginated: '/scheduled-feed-products/paginated',
    funnelStats: '/funnel-stats',
    anos: '/anos',
    anoId: '/anos/:anoId',
    invoices: '/invoices',
    invoiceId: '/invoices/:invoiceId',
    rges: '/rges',
    rgeId: '/rges/:rgeId',
    txnImportBooking: '/txns/import-booking',
    recommendations: '/recommendations',
    networks: '/networks',
}