import { IRecommendation, IUser } from "@esavvynpm/types";
import { useListUserRecommendationsQuery } from "../../Store/Users/Users.service";
import AdvTable from "../AdvTable";

function UserRecommendations(props: { user: IUser }) {

    return (
        <AdvTable<IRecommendation>
            id={props.user._id}
            idKey={'userId'}
            queryCallback={useListUserRecommendationsQuery}
            headers={[{
                label: 'Date',
                key: 'createdAt',
                dateFormat: 'basic',
                type: "date",
            }, {
                label: 'Merchant',
                key: 'merchant',
            }, {
                label: 'Title',
                key: 'pageTitle',
            }]}
            links={[{
                label: 'View',
                path: '/recommendations/{id}',
            }]}
            data={{
                cells: {
                    merchant: (key, data) => {
                        return data[key].name
                    }
                }
            }}
        />
    );
}

export default UserRecommendations;
