import { ReactNode, useCallback, useEffect, useState } from "react";
import { Col, Nav, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DateFormatType } from "../../Services/date";
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NumericFormat } from 'react-number-format';

function AdvLocalTable<T extends { [index: string]: any }>(props: {
    id?: string
    idKey?: string
    headers?: {
        label: string
        key: keyof T
        type?: "string" | "date"
        dateFormat?: DateFormatType
        disableSort?: boolean
        className?: string
        isNumber?: boolean
        prefix?: string
        format?: (value: string | number) => ReactNode
    }[]
    data?: string[][] | null
    links?: { label: string, path: string }[]
    deleteLink?: {
        callback: (id: string) => Promise<void>
        disabled: boolean
    }
    initialSort?: {
        field: string
        sort: string
    }
}) {

    const [filtered, setFiltered] = useState(props.data)
    const [sortOrder, setSortOrder] = useState<{
        field: string
        sort: string
    }>({
        field: props.initialSort ? props.initialSort.field : '_id',
        sort: props.initialSort ? props.initialSort.sort : 'desc',
    })

    const sortTable = useCallback(() => {
        if (filtered && props.headers) {
            let index = -1
            let isNumber = false
            for (let i = 0; i < props.headers?.length; i++) {
                const header = props.headers[i]
                if (header.key === sortOrder.field) {
                    index = i
                    isNumber = !!header.isNumber
                    break
                }
            }
            const sortAsc = sortOrder.sort === 'asc' ? 1 : -1
            const sortDesc = sortAsc * -1
            if (index > -1) {
                setFiltered(filtered.sort((a, b) => {
                    if (isNumber) {
                        const aP = !!a[index] ? a[index] : 0
                        const bP = !!b[index] ? b[index] : 0
                        if (+aP > +bP) return sortDesc
                        if (+aP < +bP) return sortAsc
                    } else {
                        const aP = !!a[index] ? a[index] : ''
                        const bP = !!b[index] ? b[index] : ''
                        if (aP > bP) return sortDesc
                        if (aP < bP) return sortAsc
                    }
                    return 0
                }))
            }
        }
    }, [filtered, setFiltered, props.headers, sortOrder.field, sortOrder.sort]);

    useEffect(() => {
        sortTable()
    }, [sortTable])

    useEffect(() => {
        setFiltered(props.data)
    }, [props.data])

    const sortColumn = (key: string) => {
        setSortOrder({
            field: key.toString(),
            sort: sortOrder.field !== key ? 'asc' : sortOrder.sort === 'asc' ? 'desc' : 'asc',
        })
    }

    return (
        <>
            <Row>
                <Col xs={12}>
                    Showing: <NumericFormat
                        value={filtered?.length ?? 0}
                        displayType="text"
                        thousandSeparator={true}
                        decimalScale={2}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table
                        responsive={true}
                        striped={true}
                    >
                        {props.headers ? <thead>
                            <tr>
                                {props.headers.map(header => <th key={header.key.toString()}>
                                    {header.disableSort ? header.label : <Nav.Link onClick={() => sortColumn(header.key.toString())}>
                                        <>
                                            <span style={{ marginRight: 10 }}>{header.label}</span>
                                            {sortOrder.field !== header.key ? null : sortOrder.sort === 'asc' ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
                                        </>
                                    </Nav.Link>}
                                </th>)}
                                {props.links || props.deleteLink ? <th>Links</th> : null}
                            </tr>
                        </thead> : null}
                        {filtered ? <tbody>
                            {filtered.map((row: string[], index: number) => <tr key={index}>
                                {row.map((cell, cellIndex) => {
                                    let value: string | number | ReactNode | undefined = cell
                                    if (!props.headers) {
                                        return undefined
                                    }
                                    const format = props.headers[cellIndex].format
                                    if (undefined !== format) {
                                        if (typeof value === "string" || typeof value === "number") {
                                            value = format(value)
                                        }
                                    }
                                    const className = props.headers[cellIndex].className
                                    const prefix = props.headers[cellIndex].prefix
                                    return <td className={className} key={cellIndex}>{prefix}{value}</td>
                                })}
                                {props.links ? <td key={`links_${row[0]}`}>
                                    {props.links.map(link => <Link key={`link_${link.path}`} style={{ marginRight: 10 }} to={link.path.replace('{id}', row[0])}>{link.label}</Link>)}
                                    {undefined !== props.deleteLink ? <Link to={''} onClick={(e) => {
                                        e.preventDefault()
                                        if (undefined !== props.deleteLink && !props.deleteLink.disabled) {
                                            props.deleteLink.callback(row[0])
                                        }
                                    }}>{props.deleteLink.disabled ? 'Deleting' : 'Delete'}</Link> : null}
                                </td> : null}
                            </tr>)}
                        </tbody> : null}
                    </Table>
                </Col>
            </Row>
        </>
    );
}

export default AdvLocalTable;
