import { IUser } from "@esavvynpm/types";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { useGetUserInfluencerProfileQuery, useUpdateUserInfluencerProfileMutation } from "../../Store/Users/Users.service";
import UserInfluencerEditForm, { FormInput } from "./user-influencer-edit-form";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";

function UserInfluencerProfile(props: { user: IUser }) {

    const influencerProfileQuery = useGetUserInfluencerProfileQuery(props.user._id)

    const dispatch = useDispatch<AppDispatch>()
    const [updateInfluencerProfile, updateInfluencerProfileResult] = useUpdateUserInfluencerProfileMutation()

    const handleSubmit = async (body: FormInput) => {
        try {
            await updateInfluencerProfile({
                userId: props.user._id,
                body,
            }).unwrap()
            dispatch(setMessage({
                message: 'User updated successfully',
                variant: 'success',
            }))
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="view">
            <Row className="mb-3">
                <Col xs={12}>
                    <Nav variant="pills" className="flex-row">
                        <Nav.Item>
                            <Nav.Link eventKey="view">View</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="edit">Edit</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Tab.Content>
                        <Tab.Pane eventKey="view">
                            <Card>
                                <Card.Header>Influencer Profile</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} md={6}>Instant Messaging</Col>
                                        <Col xs={12} md={6} className="text-end">{influencerProfileQuery.data?.instantMessaging ? 'Yes' : 'No'}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Email Marketing</Col>
                                        <Col xs={12} md={6} className="text-end">{influencerProfileQuery.data?.emailMarketing ? 'Yes' : 'No'}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Social Networks</Col>
                                        <Col xs={12} md={6} className="text-end">{influencerProfileQuery.data?.socialNetworks ? 'Yes' : 'No'}</Col>
                                    </Row>
                                    {influencerProfileQuery.data?.website ? <Row>
                                        <Col xs={12} md={6}>Website</Col>
                                        <Col xs={12} md={6} className="text-end">{influencerProfileQuery.data?.website}</Col>
                                    </Row> : null}
                                    {influencerProfileQuery.data?.other ? <Row>
                                        <Col xs={12} md={6}>Other</Col>
                                        <Col xs={12} md={6} className="text-end">{influencerProfileQuery.data?.other}</Col>
                                    </Row> : null}
                                </Card.Body>
                            </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="edit">
                            {influencerProfileQuery.isLoading ? 'Loading...' : null}
                            {!influencerProfileQuery.isLoading ? <UserInfluencerEditForm
                                disabled={updateInfluencerProfileResult.isLoading}
                                influencerProfile={influencerProfileQuery.data}
                                handleSubmit={handleSubmit}
                            /> : null}
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
}

export default UserInfluencerProfile;
