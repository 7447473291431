import { Button, Form, Offcanvas } from "react-bootstrap"
import { AdvTableFilterParams } from "../AdvTable/types"
import { useState } from "react"

function MerchantScrapFilter(props: {
    show: boolean
    handleClose: () => void
    filters: AdvTableFilterParams[]
    selectedFilters: AdvTableFilterParams[]
    handleFilter: (selectedFilters: AdvTableFilterParams[]) => void
}) {

    const [selectedFilters, setSelectedFilters] = useState<AdvTableFilterParams[]>(props.selectedFilters)

    return (
        <Offcanvas show={props.show} onHide={props.handleClose}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Filter</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {props.filters.map((filter, index) => <Form.Group key={index} className="mb-3" controlId={`Filter${index}`}>
                    <Form.Check
                        type="checkbox"
                        label={filter.label}
                        checked={selectedFilters.map(x => x.id).includes(filter.id)}
                       
                        onChange={(e) => {
                            const _selectedFilters = []
                            for (const selectedFilter of selectedFilters) {
                                if (selectedFilter.id !== filter.id) {
                                    _selectedFilters.push(selectedFilter)
                                }
                            }
                            if (e.target.checked) {
                                _selectedFilters.push(filter)
                            }
                            setSelectedFilters(_selectedFilters)
                        }}
                    />
                </Form.Group>)}
                <Button
                    variant="primary"
                    onClick={() => props.handleFilter(selectedFilters)}
                >Filter</Button>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default MerchantScrapFilter