import { IRewardGeneratingEvent } from "@esavvynpm/types";
import AdvTable from "../AdvTable";
import { useListRgesQuery } from "../../Store/Income/Income.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/Store";
import { useListMerchantsCompleteQuery } from "../../Store/Merchants/Merchants.service";
import { setMessage } from "../../Store/Notification/Notification.slice";

function ListRges() {

    const dispatch = useDispatch<AppDispatch>()
    useListMerchantsCompleteQuery()
    const merchantKeys = useSelector((state: RootState) => state.merchants.merchantKeys)

    const copyToClipboard = (text?: string) => {
        if (text) {
            navigator.clipboard.writeText(text)
            dispatch(setMessage({
                message: 'Copied to clipboard.',
                variant: 'success',
            }))
        }
    }

    return (
        <AdvTable<IRewardGeneratingEvent>
            queryCallback={useListRgesQuery}
            headers={[{
                label: 'Date',
                key: 'createdAt',
                dateFormat: 'short',
                type: 'date',
            }, {
                label: 'Merchant',
                key: 'data',
            }, {
                label: 'Type',
                key: 'type',
            }, {
                label: 'Status',
                key: 'status',
            }, {
                label: 'Commission',
                key: 'amount',
                className: 'text-end'
            }]}
            links={[{
                label: 'View',
                path: '/rges/{id}',
            }]}
            data={{
                cells: {
                    amount: (key, data) => {
                        return data['amount'] ? `$${data['amount'].toFixed(2)}` : ''
                    },
                    data: (key, data) => {
                        if (!data.data.merchantId || !merchantKeys) {
                            return ''
                        }
                        return <>
                            <FontAwesomeIcon icon={faCopy} color="secondary" onClick={() => copyToClipboard(data.data.merchantId)} />
                            <Link to={'/merchants/' + data.data.merchantId}>{merchantKeys[data.data.merchantId].name}</Link>
                        </>
                    },
                }
            }}
        />
    );
}

export default ListRges;
