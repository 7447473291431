import { Badge, Breadcrumb, Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetInvoiceQuery, useListInvoicesQuery } from "../../Store/Income/Income.service";
import { skipToken } from "@reduxjs/toolkit/query";
import { useListMerchantsCompleteQuery } from "../../Store/Merchants/Merchants.service";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/Store";
import { DateService } from "../../Services/date";
import { NumericFormat } from "react-number-format";
import { IInvoice } from "@esavvynpm/types";

function Invoice() {

    const params = useParams()
    const navigate = useNavigate()

    const args = params.invoiceId ? params.invoiceId : skipToken
    const invoiceQuery = useGetInvoiceQuery(args)

    const argsInvoices = invoiceQuery.data ? {
        params: {
            searchQuery: 'invoiceOrderId:' + invoiceQuery.data.invoiceOrderId,
            page: 1,
        }
    } : skipToken
    const relatedInvoicesQuery = useListInvoicesQuery(argsInvoices)

    let relatedInvoices: IInvoice[] = []
    if (relatedInvoicesQuery.data) {
        relatedInvoices = relatedInvoicesQuery.data.docs.filter(invoice => invoice._id !== params.invoiceId)
    }

    const merchantsQuery = useListMerchantsCompleteQuery()
    const merchantKeys = useSelector((state: RootState) => state.merchants.merchantKeys)

    const isLoading = invoiceQuery.isLoading || merchantsQuery.isLoading

    const merchantName = merchantKeys && invoiceQuery.data?.invoiceMerchantId ? merchantKeys[invoiceQuery.data.invoiceMerchantId].name : null

    const statusBadgeText = invoiceQuery.data?.status === "open" ? 'dark' : undefined
    const statusBadgeBg = invoiceQuery.data?.status === "closed" ? 'success' : invoiceQuery.data?.status === "open" ? 'warning' : 'danger'

    return (
        <Container fluid>
            {isLoading ? <>
                <Row>
                    <Col xs={12}>Loading...</Col>
                </Row>
            </> : <Tab.Container id="left-tabs-example" defaultActiveKey="view">
                <Row className="mb-3">
                    <Col xs={12}>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => navigate('/')}>Dash</Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => navigate('/income')}>Income</Breadcrumb.Item>
                            <Breadcrumb.Item active>{invoiceQuery.data?._id}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col xs={12}>
                        <Nav variant="pills" className="flex-row">
                            <Nav.Item>
                                <Nav.Link eventKey="view">View</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="edit">Edit</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="view">
                                <Row>
                                    <Col className="mb-3" xs={12}>
                                        <Card>
                                            <Card.Header>{merchantName} Invoice</Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs={12} md={6}>ID</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?._id}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>AURL</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.aurlId}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Recommendation</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.invoiceRecommendationId ? <Link to={'/recommendations/' + invoiceQuery.data?.invoiceRecommendationId}>{invoiceQuery.data?.invoiceRecommendationId}</Link> : null}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Preview</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.invoiceRecommendationPreviewUrl ? <Link to={invoiceQuery.data?.invoiceRecommendationPreviewUrl} target="_blank">{invoiceQuery.data?.invoiceRecommendationPreviewUrl}</Link> : null}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Image</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.invoiceRecommendationImage}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Date</Col>
                                                    <Col xs={12} md={6} className="text-end">{DateService.formatDate(invoiceQuery.data?.invoiceDate, 'short')}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Type</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.invoiceServiceType}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Status</Col>
                                                    <Col xs={12} md={6} className="text-end">
                                                        <Badge className="text-uppercase" text={statusBadgeText} bg={statusBadgeBg}>{invoiceQuery.data?.status}</Badge>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}><hr /></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Network</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.networkId}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Merchant</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.invoiceMerchantId ? <Link to={'/merchants/' + invoiceQuery.data?.invoiceMerchantId}>{invoiceQuery.data?.invoiceMerchant}</Link> : null}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>User</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.userId}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}><hr /></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Affiliate Order ID</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.invoiceOrderId}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Affiliate Network Order</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.anoId ? <Link to={'/anos/' + invoiceQuery.data?.anoId}>{invoiceQuery.data?.anoId}</Link> : null}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Reward Generating Event</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.rgeId ? <Link to={'/rges/' + invoiceQuery.data?.rgeId}>{invoiceQuery.data?.rgeId}</Link> : null}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}><hr /></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Source</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.sourceId} ({invoiceQuery.data?.sourceEntity})</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Target</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.targetEntity}</Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}><hr /></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Commission</Col>
                                                    <Col xs={12} md={6} className="text-end">
                                                        <NumericFormat
                                                            value={invoiceQuery.data?.invoiceAmount}
                                                            displayType="text"
                                                            thousandSeparator={true}
                                                            prefix="$"
                                                            decimalScale={2}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Original Commission</Col>
                                                    <Col xs={12} md={6} className="text-end">
                                                        <NumericFormat
                                                            value={invoiceQuery.data?.originalAmount}
                                                            displayType="text"
                                                            thousandSeparator={true}
                                                            prefix={invoiceQuery.data?.originalCurrency}
                                                            decimalScale={2}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={6}>Currency Rate</Col>
                                                    <Col xs={12} md={6} className="text-end">{invoiceQuery.data?.originalCurrencyRate}</Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Card>
                                            <Card.Header>Related Invoices</Card.Header>
                                            <Card.Body>
                                                {relatedInvoices ? relatedInvoices.map((invoice, index) => <>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <Row>
                                                                <Col xs={12} md={6}>ID</Col>
                                                                <Col xs={12} md={6} className="text-end"><Link to={'/invoices/' + invoice._id}>{invoice._id}</Link></Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={6}>Type</Col>
                                                                <Col xs={12} md={6} className="text-end">{invoice.invoiceServiceType}</Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={12} md={6}>Commission</Col>
                                                                <Col xs={12} md={6} className="text-end">
                                                                    <NumericFormat
                                                                        value={invoice.invoiceAmount}
                                                                        displayType="text"
                                                                        thousandSeparator={true}
                                                                        prefix="$"
                                                                        decimalScale={2}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    {index < relatedInvoices.length -1 ? <Row>
                                                        <Col xs={12}><hr /></Col>
                                                    </Row> : null}
                                                </>) : null}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>}
        </Container>
    );
}

export default Invoice;
