import { IMerchantScrap } from "@esavvynpm/types";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { DateService } from "../../Services/date";

function MerchantScrapItem(props: {
    item: IMerchantScrap
    merchantNames: { [index: string]: string }
    onDisable?: (item: IMerchantScrap) => void
    onEnable?: (item: IMerchantScrap) => void
    category: "enabled" | "scheduled" | "disabled"
    onEdit: (merchantScrap: IMerchantScrap) => void
    onSchedule?: (merchantScrap: IMerchantScrap) => void
}) {
    
    const onOpen = () => {
        window.open(props.item.url, "_blank")
    }

    return (
        <Col xs={12} md={3}>
            <Card className="mb-3">
                <Card.Img variant="top" src={props.item.imageUrl} />
                <Card.Body>
                    <Card.Title>{props.item.title}</Card.Title>
                    <Card.Text>
                        <Row>
                            <Col>{props.item.text}</Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <span>{props.item.price}</span>
                                <span style={{ textDecoration: 'line-through', marginLeft: 10 }}>{props.item.previousPrice}</span>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <div>{props.merchantNames[props.item.merchantId]}</div>
                                {props.item.createdAt ? <small>{DateService.formatDate(props.item.createdAt.toString(), "short")}</small> : null}
                            </Col>
                        </Row>
                    </Card.Text>
                    <ButtonGroup>
                        {props.onSchedule ? <Button variant="secondary" size="sm" onClick={() => {
                            if (props.onSchedule) {
                                props.onSchedule(props.item)
                            }
                        }}>Schedule</Button> : null}
                        {props.category === "enabled" ? <Button variant="secondary" size="sm" onClick={() => props.onEdit(props.item)}>Edit</Button> : null}
                        <Button variant="secondary" size="sm" onClick={onOpen}>Open</Button>
                        {props.onDisable ? <Button variant="secondary" size="sm" onClick={() => {
                            if (props.onDisable) {
                                props.onDisable(props.item)
                            }
                        }}>Disable</Button> : null}
                        {props.onEnable ? <Button variant="secondary" size="sm" onClick={() => {
                            if (props.onEnable) {
                                props.onEnable(props.item)
                            }
                        }}>Enable</Button> : null}
                    </ButtonGroup>
                </Card.Body>
            </Card>
        </Col>
    );
}

export default MerchantScrapItem;
