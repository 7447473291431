import React from "react"
import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

interface ISignInFormProps {
    handleSubmit(credentials: { email: string, password: string }): void
    disabled: boolean
    className: string
}

const SignInForm: React.FC<ISignInFormProps> = (props: ISignInFormProps) => {

    const initState = {
        email: "",
        password: "",
    }

    const [initialValues] = React.useState(initState)

    const onSubmit = (values: {
        email: string
        password: string
    }) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        // reValidateMode: "onChange",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className={props.className}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Email"
                    {...register("email", { required: "Email is required" })}
                />
                {errors.email && (
                    <Form.Text className="text-danger">
                        {errors.email.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    placeholder="Password"
                    {...register("password", { required: "Password is required" })}
                />
                {errors.password && (
                    <Form.Text className="text-danger">
                        {errors.password.message}
                    </Form.Text>
                )}
            </Form.Group>

            <div className="d-grid gap-2">
                <Button
                    type='submit'
                    variant="primary"
                    disabled={!isValid || props.disabled}
                >Sign In</Button>
            </div>
        </Form>

    )
}

export default SignInForm