import { BaseQueryApi, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Config } from '../Config'
import LocalStorageService, { LocalStorageKeys } from './LocalStorage'

export type ApiError = {
  data: {
    error: string
  },
  status: number
}

const originalBaseQuery = fetchBaseQuery({
  baseUrl: Config.SERVER_BASE_URL,
  prepareHeaders: async (headers) => {
    const token: string | null = await LocalStorageService.getItem(LocalStorageKeys.AUTH_TOKEN)
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})
const wrappedBaseQuery = (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  if (Config.isDev()) {
    console.log("api call", args)
  }
  return originalBaseQuery(args, api, extraOptions)
}

export const baseSplitApi = createApi({
  baseQuery: wrappedBaseQuery,
  tagTypes: [
    "ListUsers",
    "GetUser",
    "GetUserInfluencerProfile",
    "ListMerchants",
    "GetMerchant",
    "GetMerchantTerms",
    "ListMerchantScraps",
    "ListScheduledProducts",
    "ListAnos",
    "GetAno",
    "ListRecommendations",
    "GetRecommendation",
    "ListMerchantCategories",
    "GetMerchantCategory",
  ],
  endpoints: () => ({}),
})