import { Col, Row } from "react-bootstrap";
import { useGetStatsFeedQuery } from "../../Store/Stats/Stats.service";
import { NumericFormat } from "react-number-format";
import { FeedStat } from "../../Store/Stats/Types";
import { Link } from "react-router-dom";

function FeedStats() {

    const statsFeedQuery = useGetStatsFeedQuery()

    const data = statsFeedQuery.data

    return (
        <Row className="mb-3">
            {data ? <Col>
                <FeedStatComponent
                    stat={data.likes}
                    label="Likes"
                />
                <FeedStatComponent
                    stat={data.views}
                    label="Views"
                />
                <FeedStatComponent
                    stat={data.recommendations}
                    label="Recommendations"
                />
                <FeedStatComponent
                    stat={data.shares}
                    label="Shares"
                />
                <FeedStatComponent
                    stat={data.transactions}
                    label="Transactions"
                />
                <FeedStatComponent
                    stat={data.commission}
                    label="Commission"
                    prefix="$"
                />
            </Col> : null}
        </Row>
    );
}

function FeedStatComponent(props: {
    label: string
    stat: FeedStat
    prefix?: string
}) {
    const data = props.stat

    return (
        <Row>
            <Col>
                <Row>
                    <Col><b>Top {props.label}</b></Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col xs={12} md={8}>
                                <Link to={'/merchants/' + data.merchantId}>{data.merchantName}</Link> | {data.network}
                            </Col>
                            <Col xs={12} md={4} className="text-end">
                                <NumericFormat
                                    value={data.counter}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    prefix={props.prefix}
                                    renderText={formattedValue => <span>{formattedValue}</span>}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <hr />
            </Col>
        </Row>
    )
}

export default FeedStats;
