import { Button, Card, Col, Row } from "react-bootstrap";
import { useGetStatsIncomeByTypeQuery } from "../../Store/Stats/Stats.service";
import { useListMerchantsCompleteQuery } from "../../Store/Merchants/Merchants.service";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdvLocalTable from "../AdvLocalTable";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/Store";

type StatsIncomeByTypeData = Array<any[]>

const COMPACT_LIMIT = 5

function StatsIncomeByType() {

    useListMerchantsCompleteQuery()
    useGetStatsIncomeByTypeQuery()

    const incomeByTypeResponse = useSelector((state: RootState) => state.stats.incomeByTypeResponse)
    const merchantKeys = useSelector((state: RootState) => state.merchants.merchantKeys)

    const [compact, setCompact] = useState(true)
    const [statsData, setStatsData] = useState<StatsIncomeByTypeData | null>(null)

    useEffect(() => {
        if (incomeByTypeResponse && merchantKeys) {
            const statsData: StatsIncomeByTypeData = []
            let limit = incomeByTypeResponse.length
            if (compact && incomeByTypeResponse.length > COMPACT_LIMIT) {
                limit = COMPACT_LIMIT
            }
            for (let i = 0; i < limit; i++) {
                const stats = incomeByTypeResponse[i]
                statsData.push([
                    merchantKeys[stats._id.merchantId] ? <Link to={'/merchants/' + stats._id.merchantId}>{merchantKeys[stats._id.merchantId].name}</Link> : stats._id.merchantId,
                    merchantKeys[stats._id.merchantId] ? merchantKeys[stats._id.merchantId].network : '',
                    stats._id.isCashback ? 'Yes' : 'No',
                    stats.count.toString(),
                    `${stats.commission.toFixed(2)}`,
                ])
            }
            setStatsData(statsData)
        }
    }, [incomeByTypeResponse, merchantKeys, compact])

    return (
        <Row>
            <Col>
                {statsData ? <Card>
                    <Card.Header>Income by Type</Card.Header>
                    <Card.Body>
                        <AdvLocalTable<{
                            merchantId: string
                            network: string
                            isCashback: boolean
                            count: number
                            commission: number
                        }>
                            initialSort={{
                                field: 'count',
                                sort: 'desc',
                            }}
                            headers={[{
                                label: 'Merchant',
                                key: 'merchantId',
                                disableSort: true,
                            }, {
                                label: 'Network',
                                key: 'network',
                            }, {
                                label: 'Cashback',
                                key: 'isCashback',
                            }, {
                                label: 'Txn',
                                key: 'count',
                                className: 'text-end',
                                isNumber: true,
                            }, {
                                label: 'Commission',
                                key: 'commission',
                                className: 'text-end',
                                isNumber: true,
                                prefix: '$',
                            }]}
                            data={statsData}
                        />
                    </Card.Body>
                    {compact ? <Card.Footer className="text-center"><Button variant="link" onClick={() => setCompact(false)}>Show More</Button></Card.Footer> : null}
                </Card> : null}
            </Col>
        </Row>
    );
}

export default StatsIncomeByType;
