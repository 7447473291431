import { createSlice } from '@reduxjs/toolkit'
import { MerchantKey, MerchantsState } from './Types'
import { api } from './Merchants.service'

const initialState: MerchantsState = {
    merchantKeys: null,
}

export const statsSlice = createSlice({
    name: 'Mercants',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                api.endpoints.listMerchantsComplete.matchPending,
                (state) => {
                    state.merchantKeys = null
                }
            )
            .addMatcher(
                api.endpoints.listMerchantsComplete.matchFulfilled,
                (state, { payload }) => {
                    const merchantKeys: MerchantKey = {}
                    for (const merchant of payload) {
                        merchantKeys[merchant._id] = {
                            name: merchant.name,
                            network: merchant.network,
                        }
                    }
                    state.merchantKeys = merchantKeys
                }
            )
            .addMatcher(
                api.endpoints.listMerchantsComplete.matchRejected,
                (state) => {
                    state.merchantKeys = null
                }
            )

    }
})

export default statsSlice.reducer