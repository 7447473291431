import { useParams } from "react-router";
import NavPage from "../NavPage";
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Container } from "react-bootstrap";
import { useGetMerchantQuery } from "../../Store/Merchants/Merchants.service";
import MerchantProfile from "./merchant-profile";
import MerchantTerms from "./merchant-terms";

function Merchant() {

    const params = useParams()

    const args = params.merchantId ? params.merchantId : skipToken
    const merchantQuery = useGetMerchantQuery(args)

    return (
        <Container fluid>
            {merchantQuery.data ? <NavPage
                pageTitle={`Merchant ${merchantQuery.data?.name}`}
                items={[{
                    label: 'Profile',
                    key: '/profile',
                }, {
                    label: 'Terms',
                    key: '/terms',
                }]}
                children={{
                    '/profile': <MerchantProfile merchant={merchantQuery.data} />,
                    '/terms': <MerchantTerms merchant={merchantQuery.data} />,
                }}
            /> : 'Loading...'}
        </Container>
    );
}

export default Merchant;
