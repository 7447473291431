import { useListMerchantsCategoriesQuery } from "../../Store/Merchants/Merchants.service";
import AdvLocalTable from "../AdvLocalTable";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";

function Categories() {

    const navigate = useNavigate()
    const categoriesQuery = useListMerchantsCategoriesQuery()

    const [categoriesData, setCategoriesData] = useState<any[][] | null>(null)

    useEffect(() => {
        if (categoriesQuery.isSuccess && categoriesQuery.data?.length) {
            const categoriesData: any[][] = []

            for (let i = 0; i < categoriesQuery.data.length; i++) {
                categoriesData.push([
                    categoriesQuery.data[i]._id,
                    categoriesQuery.data[i].name,
                    !!categoriesQuery.data[i].svgXml ? 'Yes' : 'No',
                    !!categoriesQuery.data[i].isFilter ? 'Yes' : 'No',
                    !!categoriesQuery.data[i].isOnboardingProfile ? 'Yes' : 'No',
                    categoriesQuery.data[i].filterWeight,
                    categoriesQuery.data[i].stats?.views,
                    categoriesQuery.data[i].stats?.recommendations,
                    categoriesQuery.data[i].stats?.txnCount,
                ])
            }
            setCategoriesData(categoriesData)
        }
    }, [categoriesQuery.data])

    return (
        <Row>
            <Col>
                <Row className="mb-3">
                    <Col><Button
                        onClick={() => navigate('/merchant-categories/add')}
                    >Add Category</Button></Col>
                </Row>
                <Row>
                    <Col>
                        <AdvLocalTable<{
                            _id: string
                            name: string
                            svgXml: string
                            isFilter: boolean
                            isOnboardingProfile: boolean
                            filterWeight: number
                            imps: number
                            recommendations: number
                            purchases: number
                        }>
                            initialSort={{
                                field: 'filterWeight',
                                sort: 'desc',
                            }}
                            headers={[{
                                label: 'ID',
                                key: '_id',
                                disableSort: true,
                            }, {
                                label: 'Name',
                                key: 'name',
                            }, {
                                label: 'Image',
                                key: 'svgXml',
                            }, {
                                label: 'Filter',
                                key: 'isFilter',
                            }, {
                                label: 'Onboarding',
                                key: 'isOnboardingProfile',
                            }, {
                                label: 'Weight',
                                key: 'filterWeight',
                                isNumber: true,
                            }, {
                                label: 'Imps',
                                key: 'imps',
                                isNumber: true,
                                className: 'text-end',
                                format: value => <NumericFormat
                                    value={value ?? 0}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={0}
                                />,
                            }, {
                                label: 'Recs',
                                key: 'recommendations',
                                isNumber: true,
                                className: 'text-end',
                                format: value => <NumericFormat
                                    value={value ?? 0}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={0}
                                />,
                            }, {
                                label: 'Purchases',
                                key: 'purchases',
                                isNumber: true,
                                className: 'text-end',
                                format: value => <NumericFormat
                                    value={value ?? 0}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={0}
                                />,
                            }]}
                            links={[{
                                label: 'View',
                                path: '/merchant-categories/{id}',
                            }]}
                            data={categoriesData}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Categories;
