import { Card, Col, Row } from "react-bootstrap";
import StatWidget from "../StatWidget";
import { useGetFunnelStatsQuery } from "../../Store/Stats/Stats.service";
import moment from "moment";
import { faChartLine, faDollarSign, faLink, faShareAlt, faThumbsUp, faUsers } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { DateService } from "../../Services/date";

function DashStats() {

    const [dateRange] = useState<{
        startDate: string
        endDate: string
    }>({
        startDate: moment().subtract(1, 'week').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
    })

    const [compareDateRange] = useState<{
        startDate: string
        endDate: string
    }>({
        startDate: moment().subtract(2, 'week').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'week').format('YYYY-MM-DD'),
    })

    const funnelStatsQuery = useGetFunnelStatsQuery(dateRange)
    const commpareFunnelStatsQuery = useGetFunnelStatsQuery(compareDateRange)

    const txnTotal = funnelStatsQuery.data?.txnTotal ?? 0
    const txnTotalAmount = funnelStatsQuery.data?.txnTotalAmount ?? 0
    const usersTotal = funnelStatsQuery.data?.usersTotal ?? 0
    const txnPeriod = funnelStatsQuery.data?.txnPeriod ?? 0
    const txnPeriodAmount = funnelStatsQuery.data?.txnPeriodAmount ?? 0
    const usersPeriod = funnelStatsQuery.data?.usersPeriod ?? 0
    const recommendationsPeriod = funnelStatsQuery.data?.recommendationsPeriod ?? 0
    const sharesPeriod = funnelStatsQuery.data?.sharesPeriod ?? 0
    const clicksPeriod = funnelStatsQuery.data?.clicksPeriod ?? 0

    const prevTxnPeriod = commpareFunnelStatsQuery.data?.txnPeriod ?? 0
    const prevTxnPeriodAmount = commpareFunnelStatsQuery.data?.txnPeriodAmount ?? 0
    const prevUsersPeriod = commpareFunnelStatsQuery.data?.usersPeriod ?? 0
    const prevRecommendationsPeriod = commpareFunnelStatsQuery.data?.recommendationsPeriod ?? 0
    const prevSharesPeriod = commpareFunnelStatsQuery.data?.sharesPeriod ?? 0
    const prevClicksPeriod = commpareFunnelStatsQuery.data?.clicksPeriod ?? 0

    const delta = {
        txnPeriod: prevTxnPeriod > 0 ? ((100 * txnPeriod) / prevTxnPeriod) - 100 : 100,
        txnPeriodAmount: prevTxnPeriodAmount > 0 ? ((100 * txnPeriodAmount) / prevTxnPeriodAmount) - 100 : 100,
        usersPeriod: prevUsersPeriod > 0 ? ((100 * usersPeriod) / prevUsersPeriod) - 100 : 100,
        recommendationsPeriod: prevRecommendationsPeriod > 0 ? ((100 * recommendationsPeriod) / prevRecommendationsPeriod) - 100 : 100,
        sharesPeriod: prevSharesPeriod > 0 ? ((100 * sharesPeriod) / prevSharesPeriod) - 100 : 100,
        clicksPeriod: prevClicksPeriod > 0 ? ((100 * clicksPeriod) / prevClicksPeriod) - 100 : 100,
    }

    return (
        <>
            <Row>
                <Col xs={12} md={4} className="mb-3">
                    <StatWidget
                        count={txnTotal}
                        headerText="Txn"
                        icon={faChartLine}
                        linkTo="/merchants"
                        footerText="View"
                        variant="primary"
                    />
                </Col>
                <Col xs={12} md={4} className="mb-3">
                    <StatWidget
                        count={txnTotalAmount}
                        headerText="Commission"
                        icon={faDollarSign}
                        prefix="$"
                        linkTo="/merchants"
                        footerText="View"
                        variant="success"
                    />
                </Col>
                <Col xs={12} md={4} className="mb-3">
                    <StatWidget
                        count={usersTotal}
                        headerText="Users"
                        icon={faUsers}
                        linkTo="/users"
                        footerText="View"
                        variant="info"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col className="text-center">
                    <Card bg="secondary" text="white">
                        <Card.Header>{DateService.formatDate(dateRange.startDate)} - {DateService.formatDate(dateRange.endDate)} vs {DateService.formatDate(compareDateRange.startDate)} - {DateService.formatDate(compareDateRange.endDate)}</Card.Header>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} className="mb-3">
                    <StatWidget
                        count={txnPeriod}
                        percent={delta.txnPeriod}
                        headerText="Txn Period"
                        icon={faChartLine}
                        linkTo="/merchants"
                        footerText="View"
                        variant="primary"
                    />
                </Col>
                <Col xs={12} md={4} className="mb-3">
                    <StatWidget
                        count={txnPeriodAmount}
                        percent={delta.txnPeriodAmount}
                        headerText="Commission Period"
                        icon={faDollarSign}
                        prefix="$"
                        linkTo="/merchants"
                        footerText="View"
                        variant="success"
                    />
                </Col>
                <Col xs={12} md={4} className="mb-3">
                    <StatWidget
                        count={usersPeriod}
                        percent={delta.usersPeriod}
                        headerText="Users Period"
                        icon={faUsers}
                        linkTo="/users"
                        footerText="View"
                        variant="info"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} className="mb-3">
                    <StatWidget
                        count={recommendationsPeriod}
                        percent={delta.recommendationsPeriod}
                        headerText="Recommendations Period"
                        icon={faThumbsUp}
                        linkTo="/recommendations"
                        footerText="View"
                        variant="warning"
                    />
                </Col>
                <Col xs={12} md={4} className="mb-3">
                    <StatWidget
                        count={sharesPeriod}
                        percent={delta.sharesPeriod}
                        headerText="Share Period"
                        icon={faShareAlt}
                        linkTo="/recommendations"
                        footerText="View"
                        variant="danger"
                    />
                </Col>
                <Col xs={12} md={4} className="mb-3">
                    <StatWidget
                        count={clicksPeriod}
                        percent={delta.clicksPeriod}
                        headerText="Clicks Period"
                        icon={faLink}
                        linkTo="/recommendations"
                        footerText="View"
                        variant="secondary"
                    />
                </Col>
            </Row>
            <hr />
        </>
    );
}

export default DashStats;
