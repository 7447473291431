import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useListRegionsQuery } from "../../Store/Feed/Feed.service";
import { useEffect, useState } from "react";
import GeoFeedRegion from "./geo-feed-region";

function GeoFeed() {

    const [show, setShow] = useState(false)
    const regionsQuery = useListRegionsQuery()

    useEffect(() => {
        if (regionsQuery.isSuccess) {
            setShow(true)
        }
    }, [regionsQuery.isSuccess])

    let view = <Row><Col>Loading...</Col></Row>
    if (show && regionsQuery.data?.length) {
        const regions = [
            ...regionsQuery.data,
            {
                _id: 'global',
                name: 'Global',
            },
        ]
        view = <Tabs
            defaultActiveKey={regions[0]._id}
            id="uncontrolled-tab"
            className="mb-3"
        >
            {regions.map(region =>
                <Tab eventKey={region._id} title={region.name}>
                    <Row>
                        <GeoFeedRegion regionId={region._id === 'global' ? undefined : region._id} />
                    </Row>
                </Tab>
            )}
        </Tabs>
    }

    return view
}

export default GeoFeed;
