import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { Route, Routes, useLocation, useNavigate, } from 'react-router-dom';
import Main from '../Main';
import { logoutAsync } from '../../Store/Authorization/Authorization.slice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../Store/Store';
import Notification from '../Notification';
import logo from '../../Images/logo.png'
import Users from '../Users';
import Merchants from '../Merchants';
import Networks from '../Networks';
import Feed from '../Feed';
import User from '../Users/user';
import Merchant from '../Merchants/merchant';
import Income from '../Income';
import Ano from '../Income/ano';
import Invoice from '../Income/invoice';
import RewardGeneratingEvent from '../Income/rge';
import MerchantCategory from '../Merchants/merchant-category';
import MerchantCategoryAdd from '../Merchants/merchant-category-add';
import MerchantAdd from '../Merchants/merchant-add';

function Navigation() {

    const dispatch = useDispatch<AppDispatch>()
    const location = useLocation()
    const navigate = useNavigate()

    const logout = () => {
        dispatch(logoutAsync())
    }

    const onNav = (path: string) => {
        navigate(path)
    }

    return (
        <>
            <Navbar bg="dark" data-bs-theme="dark" expand="lg" className="mb-3">
                <Container>
                    <Navbar.Brand onClick={() => onNav('/')}>
                        <img
                            alt="eSavvy"
                            src={logo}
                            height="30"
                            className="d-inline-block align-top"
                        />{' '}
                        eSavvy Admin
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav activeKey={location.pathname} className="me-auto">
                            <Nav.Item>
                                <Nav.Link onClick={() => onNav('/')}>Dash</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => onNav('/users')}>Users</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => onNav('/merchants')}>Merchants</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => onNav('/networks')}>Networks</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => onNav('/feed')}>Feed</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => onNav('/income')}>Income</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Nav>
                            <NavDropdown title={<>
                                <FontAwesomeIcon icon={faUser} /> Account
                            </>} id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => onNav('/profile')}>
                                    Profile
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => logout()}>
                                    Sign out
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Notification />
            <Routes>
                <Route path='/' element={<Main />} />
                <Route path='/users' element={<Users />} />
                <Route path='/users/:userId' element={<User />} />
                <Route path='/merchants' element={<Merchants />} />
                <Route path='/merchants/add' element={<MerchantAdd />} />
                <Route path='/merchants/:merchantId' element={<Merchant />} />
                <Route path='/merchant-categories/add' element={<MerchantCategoryAdd />} />
                <Route path='/merchant-categories/:categoryId' element={<MerchantCategory />} />
                <Route path='/networks' element={<Networks />} />
                <Route path='/feed' element={<Feed />} />
                <Route path='/income' element={<Income />} />
                <Route path='/anos/:anoId' element={<Ano />} />
                <Route path='/invoices/:invoiceId' element={<Invoice />} />
                <Route path='/rges/:rgeId' element={<RewardGeneratingEvent />} />
            </Routes>
        </>
    );
}

export default Navigation;
