import { useParams } from "react-router";
import NavPage from "../NavPage";
import UserProfile from "./user-profile";
import { useGetUserQuery } from "../../Store/Users/Users.service";
import { skipToken } from '@reduxjs/toolkit/dist/query'
import UserInfluencerProfile from "./user-influencer";
import UserHistory from "./user-history";
import UserRecommendations from "./user-recommendations";
import { Container } from "react-bootstrap";

function User() {

    const params = useParams()

    const args = params.userId ? params.userId : skipToken
    const userQuery = useGetUserQuery(args)

    return (
        <Container fluid>
            {userQuery.data ? <NavPage
                pageTitle={`User ${userQuery.data?.firstName} ${userQuery.data?.lastName}`}
                items={[{
                    label: 'Profile',
                    key: '/profile',
                }, {
                    label: 'Influencer',
                    key: '/influencer-profile',
                }, {
                    label: 'History',
                    key: '/history',
                }, {
                    label: 'Recommendations',
                    key: '/recommendations',
                }]}
                children={{
                    '/profile': <UserProfile user={userQuery.data} />,
                    '/influencer-profile': <UserInfluencerProfile user={userQuery.data} />,
                    '/history': <UserHistory user={userQuery.data} />,
                    '/recommendations': <UserRecommendations user={userQuery.data} />,
                }}
            /> : 'Loading...'}
        </Container>
    );
}

export default User;
