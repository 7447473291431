import Pagination from 'react-bootstrap/Pagination';

function AdvTablePagination(props: {
    page: number
    query: any
    onPageChange: (page: number) => void
}) {
    return (
        <Pagination>
            {props.page > 3 ? <Pagination.First onClick={() => props.onPageChange(1)} /> : null}
            {props.page > 3 ? <Pagination.Prev onClick={() => props.onPageChange(props.page - 1)} /> : null}
            {props.page - 2 > 0 ? <Pagination.Item onClick={() => props.onPageChange(props.page - 2)}>{props.page - 2}</Pagination.Item> : null}
            {props.page - 1 > 0 ? <Pagination.Item onClick={() => props.onPageChange(props.page - 1)}>{props.page - 1}</Pagination.Item> : null}
            <Pagination.Item active>{props.page}</Pagination.Item>
            {props.page + 1 <= props.query.data.ntotalPages ? <Pagination.Item onClick={() => props.onPageChange(props.page + 1)}>{props.page + 1}</Pagination.Item> : null}
            {props.page + 2 <= props.query.data.ntotalPages ? <Pagination.Item onClick={() => props.onPageChange(props.page + 2)}>{props.page + 2}</Pagination.Item> : null}

            {props.query.data.nhasNextPage ? <Pagination.Next onClick={() => props.onPageChange(props.page + 1)} /> : null}
            {props.query.data.nhasNextPage ? <Pagination.Last onClick={() => props.onPageChange(props.query.data.ntotalPages)} /> : null}
        </Pagination>
    );
}

export default AdvTablePagination;