import { IMerchant } from "@esavvynpm/types";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { useGetMerchantTermsQuery, useUpdateMerchantTermsMutation } from "../../Store/Merchants/Merchants.service";
import { DateService } from "../../Services/date";
import MerchantTermsEditForm, { FormInput } from "./merchant-terms-edit-form";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";

function MerchantTerms(props: { merchant: IMerchant }) {


    const dispatch = useDispatch<AppDispatch>()
    const [updateMerchantTerms, updateMerchantTermsResult] = useUpdateMerchantTermsMutation()

    const termsQuery = useGetMerchantTermsQuery(props.merchant._id)

    const handleSubmit = async (body: FormInput) => {
        try {
            await updateMerchantTerms({
                merchantId: props.merchant._id,
                body: {
                    isActive: body.isActive,
                    terms: body.terms,
                },
            }).unwrap()
            dispatch(setMessage({
                message: 'Merchant updated successfully',
                variant: 'success',
            }))
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="view">
            <Row className="mb-3">
                <Col sm={3}>
                    <Nav variant="pills" className="flex-row">
                        <Nav.Item>
                            <Nav.Link eventKey="view">View</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="edit">Edit</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <Row>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="view">
                            <Card>
                                <Card.Header>Merchant Terms of Use</Card.Header>
                                <Card.Body>
                                    <Card.Text>{termsQuery.data?.terms}</Card.Text>
                                    <Row>
                                        <Col xs={12}><hr /></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Active</Col>
                                        <Col xs={12} md={6} className="text-end">{termsQuery.data?.isActive ? 'Yes' : 'No'}</Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>Updated on {DateService.formatDate(termsQuery.data?.termsUpdatedAt?.toString() ?? '', 'basic')}</Card.Footer>
                            </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="edit">
                            {termsQuery.isSuccess ? <MerchantTermsEditForm
                                disabled={updateMerchantTermsResult.isLoading}
                                terms={termsQuery.data?.terms ?? ''}
                                isActive={termsQuery.data?.isActive ?? true}
                                handleSubmit={handleSubmit}
                            /> : null}
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
}

export default MerchantTerms;
