import { IMerchantScrap } from "@esavvynpm/types"
import React from "react"
import { Button, Form } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import DatePicker from "react-datepicker"
import moment from "moment"

import "react-datepicker/dist/react-datepicker.css"

export type FormInput = {
    url: string
    imageUrl: string
    title: string
    description: string
    price: string
    previousPrice: string
    couponCode: string
    couponValue: string
    couponTerms: string
    publishAt: Date
    couponValidity: {
        startDate: string
        endDate: string
    }
}

interface IMerchantScrapItemScheduleFormProps {
    handleSubmit(data: FormInput): void
    merchantScrap: IMerchantScrap
    disabled: boolean
}

const MerchantScrapItemScheduleForm: React.FC<IMerchantScrapItemScheduleFormProps> = (props: IMerchantScrapItemScheduleFormProps) => {

    const initState: FormInput = {
        url: props.merchantScrap.url,
        imageUrl: props.merchantScrap.imageUrl,
        title: props.merchantScrap.title,
        description: props.merchantScrap.text ?? '',
        price: props.merchantScrap.price ?? '',
        previousPrice: props.merchantScrap.previousPrice ?? '',
        couponCode: '',
        couponValue: '',
        couponTerms: '',
        publishAt: moment().toDate(),
        couponValidity: {
            startDate: '',
            endDate: '',
        },
    }


    const [initialValues] = React.useState(initState)

    const onSubmit = (values: FormInput) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        control,
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        // reValidateMode: "onChange",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="Url">
                <Form.Label>Url</Form.Label>
                <Form.Control
                    type="url"
                    {...register("url", { required: "URL is required" })}
                />
                {errors.url && (
                    <Form.Text className="text-danger">
                        {errors.url.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="ImageUrl">
                <Form.Label>Image Url</Form.Label>
                <Form.Control
                    type="url"
                    {...register("imageUrl", { required: "Image URL is required" })}
                />
                {errors.imageUrl && (
                    <Form.Text className="text-danger">
                        {errors.imageUrl.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    {...register("title", { required: "Title is required" })}
                />
                {errors.title && (
                    <Form.Text className="text-danger">
                        {errors.title.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    type="text"
                    {...register("description")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Price">
                <Form.Label>Discount Price</Form.Label>
                <Form.Control
                    type="text"
                    {...register("price")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="PreviousPrice">
                <Form.Label>Previous Price</Form.Label>
                <Form.Control
                    type="text"
                    {...register("previousPrice")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CouponCode">
                <Form.Label>Coupon Code</Form.Label>
                <Form.Control
                    type="text"
                    {...register("couponCode")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CouponValue">
                <Form.Label>Coupon Value</Form.Label>
                <Form.Control
                    type="text"
                    {...register("couponValue")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CouponTerms">
                <Form.Label>Coupon Terms</Form.Label>
                <Form.Control
                    type="text"
                    {...register("couponTerms")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CouponStartDate">
                <Form.Label>Coupon Start Date</Form.Label>
                <Form.Control
                    type="text"
                    {...register("couponValidity.startDate")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="CouponEndDate">
                <Form.Label>Coupon End Date</Form.Label>
                <Form.Control
                    type="text"
                    {...register("couponValidity.endDate")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="PublishAt">
                <Form.Label>Publish Date</Form.Label>
                <Controller
                    name="publishAt"
                    control={control}
                    defaultValue={initialValues.publishAt}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => {
                        return <DatePicker
                            showTimeSelect
                            dateFormat="dd/MM/yyyy HH:mm"
                            onChange={onChange}
                            selected={moment(value).toDate()}
                            customInput={(<Form.Control
                                id="publishAtInput"
                                type="text"
                            />)}
                        />
                    }
                    }
                />
                {errors.publishAt && (
                    <Form.Text className="text-danger">
                        {errors.publishAt.message}
                    </Form.Text>
                )}
            </Form.Group>

            <Button
                type='submit'
                variant="primary"
                disabled={!isValid || props.disabled}
            >Save</Button>
        </Form>

    )
}

export default MerchantScrapItemScheduleForm