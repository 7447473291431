import { Container } from "react-bootstrap";
import StatsIncomeByType from "./stats-income-by-type";
import DashStats from "./stats";

function Main() {

    return (
        <Container>
            <DashStats />
            <StatsIncomeByType />
        </Container>
    );
}

export default Main;
