export type StatsState = {
    incomeByTypeResponse: StatsIncomeByTypeResponse | null
}

export type StatsIncomeByTypeData = Array<any[]>

export type StatsIncomeByTypeResponse = {
    _id: {
        merchantId: string
        isCashback: boolean
    }
    count: number
    commission: number
}[]

export type FeedStat = {
    merchantId: string
    merchantName: string
    network: string
    counter: number
}

export type FeedStats = {
    likes: FeedStat
    views: FeedStat
    recommendations: FeedStat
    shares: FeedStat
    transactions: FeedStat
    commission: FeedStat
}

export const anoStatuses = [
    'COMPLETED',
    'PENDING',
    'REJECTED'
] as const

export type anoStatusesType = typeof anoStatuses[number]
export type StatsAccounting = { [key in anoStatusesType]: StatsAccountingStatus }

export type StatsAccountingStatus = {
    totalIncome: number;
    incomeByNetwork: {
        networkId: string;
        commission: number;
    }[];
    incomeByMerchant: {
        merchantId: string;
        commission: number;
    }[];
}