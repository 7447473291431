import { IScheduledFeedProduct } from "@esavvynpm/types";
import AdvTable from "../AdvTable";
import { useDeleteScheduledFeedProductMutation, useListScheduledFeedProductsQuery } from "../../Store/Feed/Feed.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";

function ScheduledFeedList() {

    const dispatch = useDispatch<AppDispatch>()
    const [deleteProduct, deleteProductResult] = useDeleteScheduledFeedProductMutation()

    const onDeleteProduct = async (productId: string) => {
        const confirm = window.confirm(`Delete record ${productId}? Cannot be undone!`)
        if (confirm) {
            try {
                await deleteProduct(productId).unwrap()
                dispatch(setMessage({
                    message: 'User deleted successfully',
                    variant: 'success',
                }))
            } catch (err) {
                dispatch(setMessage({
                    message: (err as Error).message,
                    variant: 'danger',
                }))
            }
        }
    }

    return (
        <AdvTable<IScheduledFeedProduct>
            queryCallback={useListScheduledFeedProductsQuery}
            headers={[{
                label: 'Title',
                key: 'title',
            }, {
                label: 'Created',
                key: 'createdAt',
                dateFormat: 'short',
                type: 'date',
            }, {
                label: 'Publish',
                key: 'publishAt',
                dateFormat: 'short',
                type: 'date',
            }, {
                label: 'Published',
                key: 'publishedAt',
                dateFormat: 'short',
                type: 'date',
            }]}
            links={[{
                label: 'View',
                path: '/scheduled-feed-products/{id}',
            }]}
            deleteLink={{
                callback: onDeleteProduct,
                disabled: deleteProductResult.isLoading,
            }}
        />
    );
}

export default ScheduledFeedList;
