import { baseSplitApi } from "../../Services/BaseApi"
import { API_URLS } from "../../Services/api-urls"
import { DateRangePayload, FunnelStatsResponse } from "../Types"
import { FeedStats, StatsAccounting, StatsIncomeByTypeResponse } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        getStatsIncomeByType: build.query<StatsIncomeByTypeResponse, void>({
            query: () => API_URLS.statsIncomeByType,
        }),
        getStatsFeed: build.query<FeedStats, void>({
            query: () => API_URLS.statsFeed,
        }),
        getStatsAccounting: build.query<StatsAccounting, void>({
            query: () => API_URLS.statsAccounting,
        }),
        getFunnelStats: build.query<FunnelStatsResponse, DateRangePayload>({
            query: (body) => ({
                url: API_URLS.funnelStats,
                method: 'POST',
                body,
            }),
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetStatsIncomeByTypeQuery,
    useGetStatsFeedQuery,
    useGetFunnelStatsQuery,
    useGetStatsAccountingQuery,
} = api