import { createSlice } from '@reduxjs/toolkit'
import { StatsState } from './Types'
import { api } from './Stats.service'

const initialState: StatsState = {
    incomeByTypeResponse: null,
}

export const statsSlice = createSlice({
    name: 'Stats',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                api.endpoints.getStatsIncomeByType.matchPending,
                (state) => {
                    state.incomeByTypeResponse = null
                }
            )
            .addMatcher(
                api.endpoints.getStatsIncomeByType.matchFulfilled,
                (state, { payload }) => {
                    state.incomeByTypeResponse = payload
                }
            )
            .addMatcher(
                api.endpoints.getStatsIncomeByType.matchRejected,
                (state) => {
                    state.incomeByTypeResponse = null
                }
            )

    }
})

export default statsSlice.reducer