import { IInfluencerProfile, IRecommendation, IUser, PaginationResult } from "@esavvynpm/types"
import { baseSplitApi } from "../../Services/BaseApi"
import { API_URLS } from "../../Services/api-urls"
import { PaginatedQueryPayload, UserTimeline } from "../Types"
import { UserInfluencerProfileUpdatePayload, UserUpdatePayload } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listUsers: build.query<PaginationResult<IUser>, PaginatedQueryPayload>({
            query: ({ params }) => {
                let queryParams = `?page=${params.page}`;
                if (params.searchQuery) {
                    queryParams += `&q=${params.searchQuery}`;
                }
                if (params.field) {
                    queryParams += `&field=${params.field}`;
                }
                if (params.sort) {
                    queryParams += `&sort=${params.sort}`;
                }
                return API_URLS.listUsers + queryParams
            },
            providesTags: ["ListUsers"],
        }),
        getUser: build.query<IUser, string>({
            query: (userId) => API_URLS.userId.replace(':userId', userId),
            providesTags: ["GetUser"],
        }),
        getUserInfluencerProfile: build.query<IInfluencerProfile, string>({
            query: (userId) => API_URLS.userIdInfluencer.replace(':userId', userId),
            providesTags: ["GetUserInfluencerProfile"],
        }),
        updateUserInfluencerProfile: build.mutation<void, { userId: string, body: UserInfluencerProfileUpdatePayload }>({
            query: ({ userId, body }) => ({
                url: API_URLS.userIdInfluencer.replace(':userId', userId),
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["GetUserInfluencerProfile"]
        }),
        getUserHistory: build.query<UserTimeline, { userId: string, startDate: string, endDate: string }>({
            query: ({ userId, startDate, endDate }) => API_URLS.userIdHistory.replace(':userId', userId) + '?startDate' + startDate + '&endDate=' + endDate,
        }),
        listUserRecommendations: build.query<PaginationResult<IRecommendation>, PaginatedQueryPayload>({
            query: ({ id, idKey, params }) => {
                let queryParams = `?page=${params.page}`;
                if (params.searchQuery) {
                    queryParams += `&q=${params.searchQuery}`;
                }
                if (params.field) {
                    queryParams += `&field=${params.field}`;
                }
                if (params.sort) {
                    queryParams += `&sort=${params.sort}`;
                }
                return API_URLS.userIdRecommendations.replace(':' + idKey, id ?? '') + queryParams
            },
        }),
        updateUser: build.mutation<void, { userId: string, body: UserUpdatePayload }>({
            query: ({ userId, body }) => ({
                url: API_URLS.userId.replace(':userId', userId),
                method: 'PUT',
                body,
            }),
            invalidatesTags: ["ListUsers", "GetUser"]
        }),
        deleteUser: build.mutation<void, string>({
            query: (userId) => ({
                url: API_URLS.userId.replace(':userId', userId),
                method: 'DELETE',
            }),
            invalidatesTags: ["ListUsers", "GetUser"]
        }),
        listRecommendations: build.query<PaginationResult<IRecommendation>, PaginatedQueryPayload>({
            query: ({ params }) => {
                let queryParams = `?page=${params.page}`;
                if (params.searchQuery) {
                    queryParams += `&q=${params.searchQuery}`;
                }
                if (params.field) {
                    queryParams += `&field=${params.field}`;
                }
                if (params.sort) {
                    queryParams += `&sort=${params.sort}`;
                }
                return API_URLS.recommendations + queryParams
            },
            providesTags: ["ListRecommendations"],
        }),
    }),
    overrideExisting: false,
})

export const {
    useListUsersQuery,
    useGetUserQuery,
    useGetUserInfluencerProfileQuery,
    useUpdateUserInfluencerProfileMutation,
    useGetUserHistoryQuery,
    useListUserRecommendationsQuery,
    useUpdateUserMutation,
    useDeleteUserMutation,
    useListRecommendationsQuery,
} = api