import { Container } from "react-bootstrap";
import NavPage from "../NavPage";
import MerchantScrap from "./merchant-scrap";
import Overview from "./overview";
import ScheduledFeedList from "./scheduled-feed-list";
import GeoFeed from "./geo-feed";

function Feed() {

    return (
        <Container fluid>
            <NavPage
                pageTitle="Feed"
                items={[{
                    label: 'Overview',
                    key: '/feed',
                }, {
                    label: 'Merchant Scrap',
                    key: '/feed/merchant-scrap',
                }, {
                    label: 'Scheduled Feed',
                    key: '/scheduled-feed/list',
                }, {
                    label: 'Geo Feed',
                    key: '/geo-feed/list',
                }]}
                children={{
                    '/feed': <Overview />,
                    '/feed/merchant-scrap': <MerchantScrap />,
                    '/scheduled-feed/list': <ScheduledFeedList />,
                    '/geo-feed/list': <GeoFeed />,
                }}
            />
        </Container>
    );
}

export default Feed;
