import { IMerchant, IMerchantCategory } from "@esavvynpm/types"
import React from "react"
import { Button, Col, Form, InputGroup } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useListNetworksQuery } from "../../Store/Merchants/Merchants.service"

export type FormInput = {
    name: string;
    key: string;
    network: string;
    originalBaseLink: string;
    description: string;
    imageUrl: string;
    hostnames: string;
    categories: string[];
    isActive: boolean;
    isTop: boolean;
    weight: number;
    networkMerchantId: string;
    maxCommissionPercent: number;
}

interface IMerchantEditFormProps {
    handleSubmit(data: FormInput): void
    merchant: IMerchant
    disabled: boolean
    merchantCategories: IMerchantCategory[]
}

const MerchantEditForm: React.FC<IMerchantEditFormProps> = (props: IMerchantEditFormProps) => {

    const initState: FormInput = {
        name: props.merchant.name,
        key: props.merchant.key,
        network: props.merchant.network,
        originalBaseLink: props.merchant.originalBaseLink,
        description: props.merchant.description ?? '',
        imageUrl: props.merchant.imageUrl ?? '',
        hostnames: props.merchant.hostnames?.join("\n") ?? '',
        categories: props.merchant.categories?.map(cat => cat.esavvyId) ?? [],
        isActive: props.merchant.isActive ?? true,
        isTop: props.merchant.isTop ?? false,
        weight: props.merchant.weight ?? 0,
        networkMerchantId: props.merchant.networkMerchantId ?? '',
        maxCommissionPercent: props.merchant.maxCommissionPercent ?? 0,
    }

    const [initialValues] = React.useState(initState)

    const networksQuery = useListNetworksQuery()

    const onSubmit = (values: FormInput) => {
        props.handleSubmit(values)
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm({
        mode: "onTouched",
        reValidateMode: "onSubmit",
        // reValidateMode: "onChange",
        defaultValues: initialValues
    })

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="Name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Name"
                    {...register("name", { required: "Name is required" })}
                />
                {errors.name && (
                    <Form.Text className="text-danger">
                        {errors.name.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Key">
                <Form.Label>Key</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Key"
                    {...register("key", { required: "Key is required" })}
                />
                {errors.key && (
                    <Form.Text className="text-danger">
                        {errors.key.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    type="text"
                    as="textarea"
                    rows={4}
                    placeholder="Description"
                    {...register("description")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="OriginalBaseLink">
                <Form.Label>Original Base Link</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="https://www.example.com"
                    {...register("originalBaseLink", { required: "Original Base Link is required" })}
                />
                {errors.originalBaseLink && (
                    <Form.Text className="text-danger">
                        {errors.originalBaseLink.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="ImageUrl">
                <Form.Label>Image URL</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="https://www.example.com/image.png"
                    {...register("imageUrl", { required: "Image URL is required" })}
                />
                {errors.imageUrl && (
                    <Form.Text className="text-danger">
                        {errors.imageUrl.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="Hostnames">
                <Form.Label>Hostnames</Form.Label>
                <Form.Control
                    type="text"
                    as="textarea"
                    rows={4}
                    placeholder="www.example.com"
                    {...register("hostnames", { required: "Hostnames is required" })}
                />
                {errors.hostnames && (
                    <Form.Text className="text-danger">
                        {errors.hostnames.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="IsActive">
                <Form.Check
                    type="checkbox"
                    label="IsActive"
                    {...register("isActive")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="IsTop">
                <Form.Check
                    type="checkbox"
                    label="IsTop"
                    {...register("isTop")}
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="Weight">
                <Form.Label>Weight</Form.Label>
                <Form.Control
                    type="number"
                    placeholder="Weight"
                    {...register("weight")}
                />
            </Form.Group>
            {networksQuery.data?.length ? <Form.Group className="mb-3" as={Col} controlId="Network">
                <Form.Label>Network</Form.Label>
                <Form.Control
                    as="select"
                    {...register("network", { required: "Network is required" })}
                >
                    {networksQuery.data.map(network => <option key={network} value={network}>{network}</option>)}
                </Form.Control>
            </Form.Group> : null}
            <Form.Group className="mb-3" controlId="NetworkMerchantId">
                <Form.Label>Network Merchant Id</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="123"
                    {...register("networkMerchantId", { required: "Network Merchant Id is required" })}
                />
                {errors.networkMerchantId && (
                    <Form.Text className="text-danger">
                        {errors.networkMerchantId.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="MaxCommissionPercent">
                <Form.Label>Max Commission Percent</Form.Label>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                    <Form.Control
                        type="text"
                        placeholder="5"
                        {...register("maxCommissionPercent", { required: "Max Commission Percent is required" })}
                    />
                </InputGroup>
                {errors.maxCommissionPercent && (
                    <Form.Text className="text-danger">
                        {errors.maxCommissionPercent.message}
                    </Form.Text>
                )}
            </Form.Group>
            <Form.Group as={Col} controlId="Categories">
                <Form.Label>Categories</Form.Label>
                <Form.Control
                    as="select"
                    multiple
                    style={{ minHeight: 200 }}
                    {...register("categories")}
                >
                    {props.merchantCategories.map(cat => <option key={cat._id} value={cat._id}>{cat.name}</option>)}
                </Form.Control>
            </Form.Group>

            <Button
                style={{ marginTop: 10 }}
                type='submit'
                variant="primary"
                disabled={!isValid || props.disabled}
            >Save</Button>
        </Form>

    )
}

export default MerchantEditForm