import { baseSplitApi } from "../../Services/BaseApi"
import { API_URLS } from "../../Services/api-urls"
import { BookingTxn } from "./Types"

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        importBookingTxn: build.mutation<void, BookingTxn[]>({
            query: (body) => ({
                url: API_URLS.txnImportBooking,
                method: 'POST',
                body
            }),
        }),
    }),
    overrideExisting: false,
})

export const {
    useImportBookingTxnMutation,
} = api