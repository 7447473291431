import { Container } from "react-bootstrap";
import NavPage from "../NavPage";
import BookingTxnImport from "./booking-txn-import";
import Overview from "./overview";

function Networks() {

    return (
        <Container fluid>
            <NavPage
                pageTitle="Networks"
                items={[{
                    label: 'Overview',
                    key: '/networks',
                }, {
                    label: 'Booking Txn Import',
                    key: '/networks/booking-txn-import',
                }]}
                children={{
                    '/networks': <Overview />,
                    '/networks/booking-txn-import': <BookingTxnImport />,
                }}
            />
        </Container>
    );
}

export default Networks;
