import { IMerchantScrap } from "@esavvynpm/types"
import { baseSplitApi } from "../../Services/BaseApi"
import { API_URLS } from "../../Services/api-urls"

type MerchantScrapUpdatePayload = {
    merchantScrapId: string
    imageUrl?: string
    title?: string
    text?: string
    url?: string
    price?: string
    previousPrice?: string
    isDisabled?: boolean
    scheduledFeedProductId?: string
}

export const api = baseSplitApi.injectEndpoints({
    endpoints: (build) => ({
        listMerchantScrap: build.query<IMerchantScrap[], void>({
            query: () => API_URLS.listMerchantScrap,
            providesTags: ["ListMerchantScraps"],
        }),
        updateMerchantScrap: build.mutation<void, MerchantScrapUpdatePayload>({
            query: (params) => ({
                url: API_URLS.merchantScrapId.replace(':merchantScrapId', params.merchantScrapId),
                method: 'PUT',
                body: params,
            }),
            invalidatesTags: ["ListMerchantScraps"],
        }),
    }),
    overrideExisting: false,
})

export const {
    useListMerchantScrapQuery,
    useUpdateMerchantScrapMutation,
} = api