import { IMerchant } from "@esavvynpm/types";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { DateService } from "../../Services/date";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import MerchantEditForm, { FormInput } from "./merchant-profile-edit-form";
import { setMessage } from "../../Store/Notification/Notification.slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { useListMerchantsCategoriesQuery, useUpdateMerchantMutation } from "../../Store/Merchants/Merchants.service";

function MerchantProfile(props: { merchant: IMerchant }) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateMerchant, updateMerchantResult] = useUpdateMerchantMutation()

    const merchantCategoriesQuery = useListMerchantsCategoriesQuery()

    const handleSubmit = async (body: FormInput) => {
        try {
            await updateMerchant({
                merchantId: props.merchant._id,
                body,
            }).unwrap()
            dispatch(setMessage({
                message: 'Merchant updated successfully',
                variant: 'success',
            }))
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="view">
            <Row className="mb-3">
                <Col sm={3}>
                    <Nav variant="pills" className="flex-row">
                        <Nav.Item>
                            <Nav.Link eventKey="view">View</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="edit">Edit</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <Row>
                <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="view">

                            <Card>
                                <Card.Header>Profile</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} md={6}>Name</Col>
                                        <Col xs={12} md={6} className="text-end">{props.merchant.name}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Key</Col>
                                        <Col xs={12} md={6} className="text-end">{props.merchant.key}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Commission</Col>
                                        <Col xs={12} md={6} className="text-end">%{props.merchant.maxCommissionPercent}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Base URL</Col>
                                        <Col xs={12} md={6} className="text-end"><Link to={props.merchant.originalBaseLink} target="_blank">{props.merchant.originalBaseLink}</Link> <Link to={props.merchant.originalBaseLink} target="_blank"><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></Link></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Network</Col>
                                        <Col xs={12} md={6} className="text-end">{props.merchant.network} ({props.merchant.networkMerchantId})</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Top</Col>
                                        <Col xs={12} md={6} className="text-end">{props.merchant.isTop ? 'Yes' : 'No'}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Active</Col>
                                        <Col xs={12} md={6} className="text-end">{props.merchant.isActive ? 'Yes' : 'No'}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Join Date</Col>
                                        <Col xs={12} md={6} className="text-end">{DateService.formatDate(props.merchant.createdAt?.toString() ?? '', "basic")}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}><hr /></Col>
                                    </Row>
                                    {props.merchant.healthCheck ? <Row>
                                        <Col xs={12}>Health:</Col>
                                        <Col xs={12} md={6}>Healthy</Col>
                                        <Col xs={12} md={6} className="text-end">{props.merchant.healthCheck.isHealthy ? <span className="text-success">Healthy</span> : <span className="text-danger">Unhealthy</span>}</Col>
                                        {props.merchant.healthCheck.statusText ? <Col xs={12} md={6}>Status</Col> : null}
                                        {props.merchant.healthCheck.statusText ? <Col xs={12} md={6} className="text-end">{props.merchant.healthCheck.statusText}</Col> : null}
                                        <Col xs={12} md={6}>Start Date</Col>
                                        <Col xs={12} md={6} className="text-end">{DateService.formatDate(props.merchant.healthCheck.startDate?.toString() ?? '', "long")}</Col>
                                        <Col xs={12} md={6}>End Date</Col>
                                        <Col xs={12} md={6} className="text-end">{DateService.formatDate(props.merchant.healthCheck.endDate?.toString() ?? '', "long")}</Col>
                                    </Row> : <Row></Row>}
                                    <Row>
                                        <Col xs={12}><hr /></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>Stats:</Col>
                                        <Col xs={12} md={6}>Views</Col>
                                        <Col xs={12} md={6} className="text-end">{props.merchant.stats?.views ?? 0}</Col>
                                        <Col xs={12} md={6}>Likes</Col>
                                        <Col xs={12} md={6} className="text-end">{props.merchant.stats?.likes ?? 0}</Col>
                                        <Col xs={12} md={6}>Recommendations</Col>
                                        <Col xs={12} md={6} className="text-end">{props.merchant.stats?.recommendations ?? 0}</Col>
                                        <Col xs={12} md={6}>Shares</Col>
                                        <Col xs={12} md={6} className="text-end">{props.merchant.stats?.shares ?? 0}</Col>
                                        <Col xs={12} md={6}>Txn</Col>
                                        <Col xs={12} md={6} className="text-end">${props.merchant.stats?.txnAmount?.toFixed(2) ?? 0} ({props.merchant.stats?.txnCount})</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}><hr /></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>Hostnames:</Col>
                                        {props.merchant.hostnames?.map(hostname => <Col key={hostname} xs={12}>{hostname}</Col>)}
                                    </Row>
                                    <Row>
                                        <Col xs={12}><hr /></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>Categories:</Col>
                                        {props.merchant.categories?.map(category => <Col key={category.esavvyId} xs={12}>{category.title}</Col>)}
                                    </Row>
                                    <Row>
                                        <Col xs={12}><hr /></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>Description:</Col>
                                        <Col xs={12}>{props.merchant.description}</Col>
                                    </Row>
                                </Card.Body>
                                {props.merchant.imageUrl ? <Card.Img variant="bottom" src={props.merchant.imageUrl} /> : null}
                            </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="edit">
                            {merchantCategoriesQuery.data ? <MerchantEditForm
                                disabled={updateMerchantResult.isLoading}
                                merchant={props.merchant}
                                merchantCategories={merchantCategoriesQuery.data}
                                handleSubmit={handleSubmit}
                            /> : null}
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
}

export default MerchantProfile;
