import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ILocalStorageResponse, LocalStorageState } from './Types'
import LocalStorageService, { LocalStorageKeys } from '../../Services/LocalStorage'

const initialState: LocalStorageState = {
    localStorageLoadDataError: null,
    token: null,
    isLoadingLocalStorageData: true,
    lastAccessDate: null,
    isSettingToken: false,
    setTokenError: null,
    isSettngLastAccessDate: false,
    setLastAccessDateError: null,
    lastFeedHelpDate: null,
    lastStoresHelpDate: null,
    lastMembersHelpDate: null,
    lastActivityHelpDate: null,
    lastNotificationsHelpDate: null,
    lastAccountHelpDate: null,
}

export const localStorageSlice = createSlice({
    name: 'LocalStorage',
    initialState,
    reducers: {
        setLastAccessDate(state) {
            state.isSettngLastAccessDate = true
            state.lastAccessDate = (new Date()).toUTCString()
            state.setLastAccessDateError = null
        },
        setToken(state, action: PayloadAction<string>) {
            state.token = action.payload
        },
        clearToken(state) {
            state.token = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadLocalStorageDataAsync.pending, (state) => {
                state.isLoadingLocalStorageData = true
            })
            .addCase(loadLocalStorageDataAsync.fulfilled, (state, { payload }) => {
                state.isLoadingLocalStorageData = false
                state.token = payload.token
                state.lastAccessDate = payload.lastAccessDate
                state.lastFeedHelpDate = payload.lastFeedHelpDate
                state.lastStoresHelpDate = payload.lastStoresHelpDate
                state.lastMembersHelpDate = payload.lastMembersHelpDate
                state.lastActivityHelpDate = payload.lastActivityHelpDate
                state.lastNotificationsHelpDate = payload.lastNotificationsHelpDate
                state.lastAccountHelpDate = payload.lastAccountHelpDate
            })
            .addCase(loadLocalStorageDataAsync.rejected, (state) => {
                state.isLoadingLocalStorageData = false
            })
    }
})

export const loadLocalStorageDataAsync = createAsyncThunk(
    'localStorage/loadData',
    async () => {
        const token: string | null = await LocalStorageService.getItem(LocalStorageKeys.AUTH_TOKEN)
        const lastAccessDate: string | null = await LocalStorageService.getItem(LocalStorageKeys.LAST_ACCESS_DATE)
        const lastFeedHelpDate: string | null = await LocalStorageService.getItem(LocalStorageKeys.LAST_FEED_HELP_DATE)
        const lastStoresHelpDate: string | null = await LocalStorageService.getItem(LocalStorageKeys.LAST_STORES_HELP_DATE)
        const lastMembersHelpDate: string | null = await LocalStorageService.getItem(LocalStorageKeys.LAST_MEMBERS_HELP_DATE)
        const lastActivityHelpDate: string | null = await LocalStorageService.getItem(LocalStorageKeys.LAST_ACTIVITY_HELP_DATE)
        const lastNotificationsHelpDate: string | null = await LocalStorageService.getItem(LocalStorageKeys.LAST_NOTIFICATIONS_HELP_DATE)
        const lastAccountHelpDate: string | null = await LocalStorageService.getItem(LocalStorageKeys.LAST_ACCOUNT_HELP_DATE)
        const localStorageResponse: ILocalStorageResponse = {
            token,
            lastAccessDate,
            lastFeedHelpDate,
            lastStoresHelpDate,
            lastMembersHelpDate,
            lastActivityHelpDate,
            lastNotificationsHelpDate,
            lastAccountHelpDate,
        }
        return localStorageResponse
    }
)

export const {
    setLastAccessDate,
    setToken,
    clearToken,
} = localStorageSlice.actions

export default localStorageSlice.reducer