import { Col, Container, Row, Spinner } from "react-bootstrap";
import logo from "../../Images/logo.png"

function Splash() {
    return (
        <Container>
            <Row>
                <Col xs={12} md={6} className="mx-auto text-center pt-5">
                    <img src={logo} alt="eSavvy" width={100} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="text-center pt-5">
                    <div className="mb-2">Please wait...</div>
                    <Spinner animation="border" role="status" variant="secondary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Col>
            </Row>
        </Container>
    );
}

export default Splash;
