import { IUser } from "@esavvynpm/types";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import { DateService } from "../../Services/date";
import UserEditForm, { FormInput } from "./user-edit-form";
import { useUpdateUserMutation } from "../../Store/Users/Users.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";

function UserProfile(props: { user: IUser }) {

    const dispatch = useDispatch<AppDispatch>()
    const [updateUser, updateUserResult] = useUpdateUserMutation()

    const handleSubmit = async (body: FormInput) => {
        try {
            await updateUser({
                userId: props.user._id,
                body,
            }).unwrap()
            dispatch(setMessage({
                message: 'User updated successfully',
                variant: 'success',
            }))
        } catch (err) {
            dispatch(setMessage({
                message: (err as Error).message,
                variant: 'danger',
            }))
        }
    }

    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="view">
            <Row className="mb-3">
                <Col xs={12}>
                    <Nav variant="pills" className="flex-row">
                        <Nav.Item>
                            <Nav.Link eventKey="view">View</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="edit">Edit</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Tab.Content>
                        <Tab.Pane eventKey="view">
                            <Card>
                                <Card.Header>Profile</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} md={6}>Name</Col>
                                        <Col xs={12} md={6} className="text-end">{props.user.firstName} {props.user.lastName}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Email</Col>
                                        <Col xs={12} md={6} className="text-end">{props.user.email}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Phone</Col>
                                        <Col xs={12} md={6} className="text-end">{props.user.phone}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Country</Col>
                                        <Col xs={12} md={6} className="text-end">{props.user.countryCode}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>DOB</Col>
                                        <Col xs={12} md={6} className="text-end">{DateService.formatDate(props.user.dob ?? '', "basic")}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>Last access</Col>
                                        <Col xs={12} md={6} className="text-end">{DateService.formatDate(props.user.lastServerOpDate?.toString() ?? '', "short")}</Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="edit">
                            <UserEditForm
                                disabled={updateUserResult.isLoading}
                                user={props.user}
                                handleSubmit={handleSubmit}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
}

export default UserProfile;
