import { IInvoice } from "@esavvynpm/types";
import AdvTable from "../AdvTable";
import { useListInvoicesQuery } from "../../Store/Income/Income.service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store/Store";
import { useListMerchantsCompleteQuery } from "../../Store/Merchants/Merchants.service";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { setMessage } from "../../Store/Notification/Notification.slice";

function ListInvoices() {

    const dispatch = useDispatch<AppDispatch>()
    useListMerchantsCompleteQuery()
    const merchantKeys = useSelector((state: RootState) => state.merchants.merchantKeys)

    const copyToClipboard = (text?: string) => {
        if (text) {
            navigator.clipboard.writeText(text)
            dispatch(setMessage({
                message: 'Copied to clipboard.',
                variant: 'success',
            }))
        }
    }

    return (
        <>
            {merchantKeys ? <AdvTable<IInvoice>
                queryCallback={useListInvoicesQuery}
                headers={[{
                    label: 'Date',
                    key: 'invoiceDate',
                    dateFormat: 'short',
                    type: 'date',
                }, {
                    label: 'Merchant',
                    key: 'invoiceMerchantId',
                }, {
                    label: 'Status',
                    key: 'status',
                }, {
                    label: 'Commission',
                    key: 'invoiceAmount',
                    className: 'text-end'
                }, {
                    label: 'Type',
                    key: 'invoiceServiceType',
                }]}
                links={[{
                    label: 'View',
                    path: '/invoices/{id}',
                }]}
                data={{
                    cells: {
                        invoiceAmount: (key, data) => {
                            return key && data && data[key] ? `$${data['invoiceAmount'].toFixed(2)}` : ''
                        },
                        invoiceMerchantId: (key, data) => {
                            if (!data['invoiceMerchantId']) {
                                return ''
                            }
                            return <>
                                <FontAwesomeIcon icon={faCopy} color="secondary" onClick={() => copyToClipboard(data['invoiceMerchantId'])} />
                                <Link to={'/merchants/' + data['invoiceMerchantId']}>{merchantKeys[data['invoiceMerchantId']].name}</Link>
                            </>
                        },
                        status: (key, data) => {
                            const status = data['status']
                            const statusBadgeText = status === "open" ? 'dark' : undefined
                            const statusBadgeBg = status === "closed" ? 'success' : status === "open" ? 'warning' : 'danger'
                            return <Badge className="text-uppercase" text={statusBadgeText} bg={statusBadgeBg}>{status}</Badge>
                        }
                    }
                }}
            /> : null}
        </>
    );
}

export default ListInvoices;
